import { useMemo } from "react";
import { CircularProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { CartesianGrid, Legend, XAxis, YAxis, Tooltip, Line, LineChart, Bar, BarChart } from 'recharts';
import { ageGroups, genderFields } from "../helpFunctions";

export const AgeGraph = ({ isLoading, ageData, ageDataGender, splitByGender }) => {
    const chartData = useMemo(() => {
        let data = []

        if (splitByGender === true) {
            for (const key in ageGroups) {
                if (Object.prototype.hasOwnProperty.call(ageGroups, key)) {
                    const group = ageGroups[key];

                    let totalMale = 0;
                    let totalFemale = 0;
                    let totalOther = 0;

                    for (let index = group.from; index <= group.to; index++) {
                        if (ageDataGender['Male'] != null && ageDataGender['Male'][index] != null && isNaN(ageDataGender['Male'][index]) === false) {
                            totalMale += Number(ageDataGender['Male'][index])
                        }
                        if (ageDataGender['Female'] != null && ageDataGender['Female'][index] != null && isNaN(ageDataGender['Female'][index]) === false) {
                            totalFemale += Number(ageDataGender['Female'][index])
                        }
                        if (ageDataGender['Other'] != null && ageDataGender['Other'][index] != null && isNaN(ageDataGender['Other'][index]) === false) {
                            totalOther += Number(ageDataGender['Other'][index])
                        }
                    }

                    data.push({ group: key, male: totalMale, female: totalFemale, other: totalOther });
                }
            }
        }
        else {
            for (const key in ageGroups) {
                if (Object.prototype.hasOwnProperty.call(ageGroups, key)) {
                    const group = ageGroups[key];

                    let total = 0;

                    for (let index = group.from; index <= group.to; index++) {
                        if (ageData[index] != null && isNaN(ageData[index]) === false) {
                            total += Number(ageData[index])
                        }
                    }

                    data.push({ group: key, count: total });
                }
            }
        }

        return data;
    }, [ageData, ageDataGender, splitByGender]);

    return (
        <>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            ) : (
                <Stack spacing={5}>
                    <BarChart
                        width={1000}
                        height={400}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="group" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {splitByGender === true ? (
                            <>
                                <Bar dataKey="male" fill="#28a745" />
                                <Bar dataKey="female" fill="#fd7e14" />
                                <Bar dataKey="other" fill="#6610f2" />
                            </>
                        ) : (
                            <>
                                <Bar dataKey="count" fill="#007bff" />
                            </>
                        )}
                    </BarChart>
                    <Table
                        sx={{ justifyContent: 'center' }}
                        aria-labelledby="statsTable"
                        aria-label="Stats Table">
                        <TableHead>
                            <TableRow>
                                <TableCell key={"ageHeader"}>Age</TableCell>
                                {splitByGender === true ? (
                                    <>
                                        {Object.keys(genderFields).map((gender) => {
                                            return (
                                                <TableCell key={gender}>
                                                    {genderFields[gender]}
                                                </TableCell>);
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <TableCell key={"userHeader"}>Number of users</TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {chartData.map((ageGroup, index) => {
                                if (splitByGender === true) {
                                    return (
                                        <TableRow key={ageGroup.group + "-gender"}>
                                            <TableCell key={ageGroup.group + '- title'}>
                                                {ageGroup.group}
                                            </TableCell>
                                            {Object.keys(genderFields).map((gender) => {
                                                return (
                                                    <TableCell key={ageGroup.group + '-' + gender}>
                                                        {ageGroup[gender.toLocaleLowerCase()] || 0}
                                                    </TableCell>);
                                            })}
                                        </TableRow>
                                    )
                                }
                                else {
                                    return (
                                        <TableRow key={ageGroup.group + "-row"}>
                                            <TableCell key={"ageField-" + ageGroup.group + "-" + index}>
                                                {ageGroup.group}
                                            </TableCell>
                                            <TableCell key={"ageData-" + ageGroup.group + "-" + index}>
                                                {ageGroup.count}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            })}
                        </TableBody>
                    </Table>
                </Stack>
            )}
        </>
    )
}