import { useMemo, useState } from "react";
import { Box } from "@mui/material"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"

const columns = [
    { field: 'userCreated', headerName: 'User created', width: 180 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'prefLang', headerName: 'Preferred language', width: 110 },
    { field: 'ibo', headerName: 'IBO', width: 180 },
    { field: 'iboStatus', headerName: 'IBO Status', width: 120 },
    { field: 'lastSignin', headerName: 'Last Signin', width: 180 },
]


export const UsersTable = ({ userData }) => {
    const [isLoading, setIsLoading] = useState(true);

    const dataRows = useMemo(() => {
        setIsLoading(true);
        if (userData != null){
            if (userData.length > 0) {
                setIsLoading(false);
                return userData;
            }
            else{
                return [];
            }
        }
        else{
            setIsLoading(false);
            return [];
        }
    }, [userData]);

    return (
        <Box height={'800px'}>
            <DataGrid
                autoPageSize
                loading={isLoading}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'userCreated', sort: 'desc' }],
                    },
                }}
                rows={dataRows}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
            />
        </Box>
    );
}