import { useState } from "react";
import { Stack, Switch } from "@mui/material";
import { StatsGraphsParticipants } from "./components/StatsGraphsParticipants";
import { StatsGraphsIBO } from "./components/StatsGraphsIBO";

export const StatsGraphTab = () => {
    const [graphMode, setGraphMode] = useState('user');

    const switchMode = () => {
        if (graphMode === 'user') {
            setGraphMode('ibo');
        }
        else {
            setGraphMode('user');
        }
    }

    return (
        <div>
            <Stack direction={'row'} alignItems={'center'}>
                <div>
                    IBO statistics
                </div>
                <Switch
                    checked={graphMode === 'user'}
                    onChange={switchMode}
                />
                <div>
                    Participant statistics
                </div>
            </Stack>

            {graphMode === 'user' ? (
                <StatsGraphsParticipants />
            ) : (
                <StatsGraphsIBO />
            )}
        </div>
    )
}