import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react"
import { db } from "../../firebase";

export const useGetAllIBOUsers = () => {
    const [IOBUsersList, setIOBUsersList] = useState(Object);

    useEffect(() => {
        const getAllUserInfo = async () => {
            const userSnap = await getDocs(query(collection(db, 'Users'), where('role', '==', 1)));

            const userData = {};
            userSnap.forEach((child) => {
                userData[child.id] = {id: child.id, ...child.data()};
            });

            setIOBUsersList(userData);
        };

        getAllUserInfo();
    }, []);

    return IOBUsersList;
}