import { useEffect, useMemo, useState } from "react"
import { Box } from "@mui/material"
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useGetFullIBOData } from "../../../../hooks/projectData/useGetFullIBOData";
import { capitalizeWord } from "../../../../helper/stringHelpers";

const columns = [
    { field: 'name', headerName: 'Name/Company name', width: 280 },
    { field: 'track', headerName: 'Track', width: 110 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'compForm', headerName: 'Company form', width: 150 },
    { field: 'numUsers', headerName: 'Number of users', width: 150 },
    { field: 'assignedBD', headerName: 'Assigned BD', width: 190 },
    { field: 'foodConn', headerName: 'Food connection', width: 160 },
]


export const IBOTableStats = () => {
    const [isLoading, setIsLoading] = useState(true);
    const fullIBOList = useGetFullIBOData();
    const dataRows = useMemo(() => {
        if (fullIBOList != null && fullIBOList.length > 0) {
            setIsLoading(true);
            let rowList = [];

            for (let index = 0; index < fullIBOList.length; index++) {
                const item = fullIBOList[index];
                let name = item?.projectName;
                let compForm = "";
                let status = "";
                let track = "-";
                let numUsers = 0;
                let assignedBD = ""
                let foodConn = "";

                if (item?.compData?.compName != null) {
                    name = item?.compData?.compName;
                }

                if (item?.compData?.compForm != null) {
                    compForm = item?.compData?.compForm;
                }

                if (item?.activeTrack != null) {
                    track = String(item?.activeTrack).toLocaleUpperCase();
                }
                else if (item?.currentStep != null && item?.currentStep !== 'PreMeet' && item?.currentStep !== 'First Meet') {
                    track = "START";
                }

                if (item?.BDOwnerData != null) {
                    assignedBD = item?.BDOwnerData?.value;
                }
                else {
                    assignedBD = "Unassigned"
                }

                if (item?.IBOOwner != null && Array.isArray(item?.IBOOwner)) {
                    numUsers = item?.IBOOwner.length;
                }

                if (item?.isActive != null) {
                    switch (item?.isActive) {
                        case 'blocked':
                            status = "Inactive (Blocked/not applicable)";
                            break;
                        case 'paused':
                            status = "Paused";
                            break;
                        case 'aborted':
                            status = "Inactive (Aborted on IBOs request)";
                            break;
                        case 'exit':
                            status = "Inactive (Has exited)";
                            break;
                        case false:
                            status = "Inactive";
                            break;
                        case true:
                            status = "Active";
                            break;
                        default:
                            status = "Active";
                            break;
                    }
                }

                if (item?.entryQuestions != null && item?.entryQuestions?.foodConnection?.answer != null) {
                    foodConn = capitalizeWord(item?.entryQuestions?.foodConnection?.answer);
                }
                else {
                    foodConn = "No answer"
                }

                rowList.push({
                    id: item?.id,
                    name: name,
                    track: track,
                    status: status,
                    compForm: compForm,
                    numUsers: numUsers,
                    assignedBD: assignedBD,
                    foodConn: foodConn
                });
            }
            setIsLoading(false);
            return rowList;
        }
        else {
            return [];
        }
    }, [fullIBOList]);

    return (
        <Box height={'800px'}>
            <DataGrid
                autoPageSize
                loading={isLoading}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                }}
                rows={dataRows}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
            />
        </Box>
    )
}