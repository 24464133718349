import { FormControl, Grid2, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Tooltip } from "@mui/material"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useAuth } from "../../../contexts/AuthContext";
import { useMemo } from "react";
import { ExtendedCompanyQuestionsEng, ExtendedCompanyQuestionsSwe } from "../../../constants/companyQuestions/companyQuestions";

export const OwnershipCardRow = ({ item, index, handleChangeOwnerType, handleChangeText, handleChangeProcent, handleChangeCommitment, deleteRow, isOwnershipInvalid }) => {
    const { currentUser } = useAuth();

    const CompOwnershipQuestion = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return ExtendedCompanyQuestionsSwe.compOwnership;
        }
        else {
            return ExtendedCompanyQuestionsEng.compOwnership;
        }
    }, [currentUser]);

    return (
        <Grid2 container spacing={.3} sx={{ width: '100%', mb: '5px' }} justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
            <Grid2 size={{ xs: 3.5 }}>
                <FormControl size="small" required fullWidth>
                    <InputLabel>{CompOwnershipQuestion.ownerTypeLabel}</InputLabel>
                    <Select
                        sx={{ mt: '2px', height: '38px' }}
                        value={item?.ownerType || ""}
                        label={CompOwnershipQuestion.ownerTypeLabel}
                        onChange={(e) => handleChangeOwnerType(index, e.target.value)}
                        error={isOwnershipInvalid && (item?.ownerType == null || item?.ownerType === "")}
                    >
                        {CompOwnershipQuestion.ownerTypeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid2>

            <Grid2 size={{ xs: 3.8 }}>
                <TextField
                    label={CompOwnershipQuestion.ownerNameLabel}
                    required
                    size="small"
                    value={item?.text || ""}
                    onChange={(e) => handleChangeText(index, e.target.value)}
                    error={isOwnershipInvalid && (item?.text == null || item?.text === "")}
                />
            </Grid2>

            <Grid2 size={{ xs: 2 }}>
                <Stack>
                    <FormControl required fullWidth>
                        <InputLabel>{CompOwnershipQuestion.ownershipLabel}</InputLabel>
                        <OutlinedInput
                            label={CompOwnershipQuestion.ownershipLabel}
                            type="number"
                            size="small"
                            inputProps={{
                                min: 0,
                                max: 100
                            }}
                            sx={{ height: '40px' }}
                            value={item?.procent || 0}
                            onChange={(e) => handleChangeProcent(index, e.target.value)}
                        />
                    </FormControl>
                </Stack>
            </Grid2>

            <Grid2 size={{ xs: 1.7 }}>
                {item?.ownerType === 'privateFounder' || item?.ownerType === 'compFounder' ? (
                    <Stack>
                        <Tooltip arrow placement="top" title={CompOwnershipQuestion.ownerTimeComittmentLabel}>
                            <FormControl required fullWidth>
                                <InputLabel>{CompOwnershipQuestion.ownerTimeComittmentLabel}</InputLabel>
                                <OutlinedInput
                                    label={CompOwnershipQuestion.ownerTimeComittmentLabel}
                                    type="number"
                                    size="small"
                                    inputProps={{
                                        min: 0,
                                        max: 100
                                    }}
                                    sx={{ height: '40px' }}
                                    value={item?.comittment || 0}
                                    onChange={(e) => handleChangeCommitment(index, e.target.value)}
                                />
                            </FormControl>
                        </Tooltip>
                    </Stack>
                ) : ("")}

            </Grid2>

            <Grid2 size={{ xs: 0.5 }}>
                {index > 0 ? (
                    <IconButton onClick={deleteRow} sx={{ height: '22px', width: '22px' }} size="small">
                        <HighlightOffIcon sx={{ height: '22px' }} htmlColor="#ac2020" />
                    </IconButton>
                ) : (
                    <div style={{ width: '25px' }}></div>
                )}
            </Grid2>
        </Grid2>
    )
}