import { useState } from "react";
import { Box, FormControl, FormControlLabel, FormGroup, Grid2, InputLabel, MenuItem, Select, Stack } from "@mui/material"
import { IBOTableStats } from "./components/Tables/IBOTable";
import { ParticipantsTable } from "./components/Tables/ParticipantTable";
import { UsersTable } from "./components/Tables/UsersTable";
import { useGetAllUserData } from "../../hooks/userData/useGetAllUserData";

export const StatsTablesTab = () => {
    const userDataList = useGetAllUserData();
    const [category, setCategory] = useState('');

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    const renderTable = () => {
        switch (category) {
            case 'ibo':
                return (
                    <IBOTableStats />
                );
            case 'participants':
                return (
                    <ParticipantsTable userData={userDataList} />
                );
            case 'users':
                return (
                    <UsersTable userData={userDataList} />
                );
            default:
                return (
                    <Box>

                    </Box>
                )
        }
    }

    return (
        <Box>
            <Stack justifyContent={"center"} style={{ marginBottom: '40px' }}>
                <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent={'left'}>
                    <Grid2 size={{ xs: 2, sm: 4, md: 4 }}>

                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', justifyContent: 'left' }}>
                                Show table for:
                            </div>
                           <FormGroup>
                                <FormControl size='small' fullWidth>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        style={{ width: "220px" }}
                                        value={category}
                                        label="Category"
                                        onChange={handleChangeCategory}
                                    >
                                        <MenuItem value={''}></MenuItem>
                                        <MenuItem value={'ibo'}>IBOs</MenuItem>
                                        <MenuItem value={'participants'}>Participants</MenuItem>
                                        <MenuItem value={'users'}>Users/email</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </FormControl>
                    </Grid2>

                </Grid2>
            </Stack>

            <hr />
            <Box sx={{ display: 'flex', marginTop: '30px', justifyContent: "center" }}>
                {renderTable()}
            </Box>
        </Box>
    )
}