import { Box, FormControl, FormGroup, Grid2, InputLabel, MenuItem, Select, Stack } from "@mui/material"
import { useEffect, useState } from "react";
import { StartParticipantsTable } from "./components/ReportTables/startParticipantsTable";
import { useGetReportsData } from "../../hooks/statistics/useGetReportsData";
import { ScaleParticipantsTable } from "./components/ReportTables/scaleParticipantsTable";
import { IncubatorParticipantsTable } from "./components/ReportTables/incubatorParticipantsTable";
import { ExitedParticipantsTable } from "./components/ReportTables/exitedPatricipantsTable";
import { AllParticipantsTable } from "./components/ReportTables/allParticipationTable";


export const StatsReportTab = () => {
    const fullReportData = useGetReportsData();

    const [category, setCategory] = useState('');

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    const renderTable = () => {
        switch (category) {
            case 'all':
                return (
                    <div>
                        Under construction...
                        {/*<AllParticipantsTable reportData={fullReportData} />*/}
                    </div>
                );
            case 'start':
                return (
                    <StartParticipantsTable reportData={fullReportData} />
                );
            case 'incubator':
                return (
                    <IncubatorParticipantsTable reportData={fullReportData} />
                );
            case 'scale':
                return (
                    <ScaleParticipantsTable reportData={fullReportData} />
                );
            case 'exit':
                return (
                    <ExitedParticipantsTable reportData={fullReportData} />
                );
            default:
                return (
                    <Box>

                    </Box>
                )
        }
    }

    return (
        <Box>
            <Stack justifyContent={"center"} style={{ marginBottom: '40px' }}>
                <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent={'left'}>
                    <Grid2 size={{ xs: 2, sm: 4, md: 4 }}>

                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', justifyContent: 'left' }}>
                                Show report for:
                            </div>
                            <FormGroup>
                                <FormControl size='small' fullWidth>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        style={{ width: "300px" }}
                                        value={category}
                                        label="Category"
                                        onChange={handleChangeCategory}
                                    >
                                        <MenuItem value={''}></MenuItem>
                                        <MenuItem value={'all'}>IBO participation (All)</MenuItem>
                                        <MenuItem value={'start'}>IBO participation (START)</MenuItem>
                                        <MenuItem value={'incubator'}>IBO participation (INCUBATOR)</MenuItem>
                                        <MenuItem value={'scale'}>IBO participation (SCALE)</MenuItem>
                                        <MenuItem value={'exit'}>EXIT overview</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </FormControl>
                    </Grid2>

                </Grid2>
            </Stack>

            <hr />
            <Box sx={{ display: 'flex', marginTop: '30px', justifyContent: "center" }}>
                {renderTable()}
            </Box>
        </Box>
    )
}