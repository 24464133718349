import { useMemo } from "react";
import { CircularProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip } from 'recharts';
import { capitalizeWord } from "../../../../../helper/stringHelpers";

export const FemaleRepGraph = ({ isLoading, femaleRepData }) => {
    const chartData = useMemo(() => {
        let data = []

        Object.keys(femaleRepData).forEach((key) => {
            data.push({ name: capitalizeWord(key), count: femaleRepData[key] });
        });

        return data;
    }, [femaleRepData]);

    return (
        <>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            ) : (
                <Stack spacing={3}>
                    <BarChart
                        width={500}
                        height={300}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="count" fill="#007bff" />
                    </BarChart>

                    <Table
                        sx={{ justifyContent: 'center' }}
                        aria-labelledby="statsTable"
                        aria-label="Stats Table">
                        <TableHead>
                            <TableRow>
                                {Object.keys(femaleRepData).map((option) => {
                                    return (
                                        <TableCell key={option + "-header"}>
                                            {capitalizeWord(option)}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {Object.keys(femaleRepData).map((option, index) => {
                                    return (
                                        <TableCell key={option + "-" + index}>
                                            {femaleRepData[option]}
                                        </TableCell>);
                                })}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            )}
        </>
    )
}