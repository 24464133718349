import { collectionGroup, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react"
import { db } from "../../firebase";

export const useGetAllEntryQuestionAnswers = () => {
    const [entryQuestionList, setEntryQuestionList] = useState(Object);

    useEffect(() => {
        const getAllProjectsEntryInfo = async () => {
            const questionsSnap = await getDocs(collectionGroup(db, 'Questions'));

            const questionData = {};
            questionsSnap.forEach((child) => {
                if (child.ref.parent?.parent != null && child.ref.parent?.parent?.parent?.parent != null) {
                    const projID = child.ref.parent?.parent?.parent?.parent?.id;
                    //console.log("ID List: ", child.ref.parent?.parent?.id, child.ref.parent?.parent?.parent?.parent?.id)
                    if (child.ref.parent?.parent?.id === 'a' && projID != null) {
                        if (questionData[projID] != null) {
                            questionData[projID][child.id] = child.data();
                        }
                        else {
                            questionData[projID] = {
                                [child.id]: child.data(),
                            }
                        }
                    }
                }
            });

            //console.log("Data Res: ", questionData)
            setEntryQuestionList(questionData);
        };

        getAllProjectsEntryInfo();
    }, []);

    return entryQuestionList;
}