import { useEffect, useState } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import firebaseRefList from "../../../firebase/firebaseRefList";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Grid2, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GenderGraph } from "./Graphs/Participants/genderGraph";
import { EducationGraph } from "./Graphs/Participants/educationGraph";
import { RegionGraph } from "./Graphs/Participants/regionGraph";
import { AgeGraph } from "./Graphs/Participants/ageGraphs";
import { HKRConnGraph } from "./Graphs/Participants/HKRConnGraph";
import { ReferenceGraph } from "./Graphs/Participants/referenceGraph";

const eduFields = {
    elementary: "Elementary School",
    secondary: "Secondary / High School",
    postSub3: "Post secondary education < 3 years",
    post3to5: "Post secondary education 3-5 years",
    postabove5: "Post secondary education > 5 years",
    phd: "PhD",
}

export const StatsGraphsParticipants = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState('');
    const [completedOnly, setCompletedOnly] = useState(true);

    const [genderData, setGenderData] = useState({
        Male: 0,
        Female: 0,
        Other: 0
    });
    const [eduData, setEduData] = useState({
        elementary: 0,
        secondary: 0,
        postSub3: 0,
        post3to5: 0,
        postabove5: 0,
        phd: 0,
    });
    const [countryData, setCountryData] = useState({});
    const [ageData, setAgeData] = useState({});
    const [hkrData, setHkrData] = useState({});
    const [refData, setRefData] = useState({});

    const [combDataEdu, setCombDataEdu] = useState({});
    const [combDataCountry, setCombDataCountry] = useState({});
    const [combDataAge, setCombDataAge] = useState({});
    const [combDataHkr, setCombDataHkr] = useState({});
    const [combDataRef, setCombDataRef] = useState({});

    const [selectedDateFrom, setSelectedDateFrom] = useState(null);
    const [selectedDateTo, setSelectedDateTo] = useState(null);


    const [selection, setSelection] = useState({
        gender: false,
        education: false,
    });

    const handleChange = (event) => {
        setSelection({
            ...selection,
            [event.target.name]: event.target.checked,
        });
    };

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    const handleDateChangeFrom = (newValue) => {
        if (newValue != null) {
            setSelectedDateFrom(newValue);

        } else {
            setSelectedDateFrom(null)
        }
    };

    const handleDateChangeTo = (newValue) => {
        if (newValue != null) {
            setSelectedDateTo(newValue);

        } else {
            setSelectedDateTo(null)
        }
    };

    const { gender } = selection;

    useEffect(() => {
        setIsLoading(true);
        const getStatistics = async () => {
            let whereDataSet;
            if (completedOnly) {
                whereDataSet = where('dataSet', '>=', 5);
            }
            else{
                whereDataSet = where('dataSet', '>=', 2);
            }

            //console.log("Date: ", selectedDateTo, selectedDateFrom)
            if ((selectedDateFrom == null || selectedDateFrom === "") && (selectedDateTo == null || selectedDateTo === "")) {
                const subscriber = onSnapshot(query(collection(db, 'Users'), where('role', '==', 1), whereDataSet), querySnapshot => {
                    saveData(querySnapshot);
                });

                firebaseRefList.push(subscriber);
            }
            else if ((selectedDateFrom == null || selectedDateFrom === "") && selectedDateTo != null && selectedDateTo !== "") {
                // @ts-ignore
                const subscriber = onSnapshot(query(collection(db, 'Users'), where('role', '==', 1), whereDataSet, where("created", "<=", selectedDateTo['$d'])), querySnapshot => {
                    saveData(querySnapshot);
                });

                firebaseRefList.push(subscriber);
            }
            else if (selectedDateFrom != null && selectedDateFrom !== "" && (selectedDateTo == null || selectedDateTo === "")) {
                // @ts-ignore
                const subscriber = onSnapshot(query(collection(db, 'Users'), where('role', '==', 1), whereDataSet, where("created", ">=", selectedDateFrom['$d'])), querySnapshot => {
                    saveData(querySnapshot);
                });

                firebaseRefList.push(subscriber);
            }
            else if (selectedDateFrom != null && selectedDateFrom !== "" && selectedDateTo != null && selectedDateTo !== "") {
                // @ts-ignore
                const subscriber = onSnapshot(query(collection(db, 'Users'), where('role', '==', 1), whereDataSet, where("created", "<=", selectedDateTo['$d']), where("created", ">=", selectedDateFrom['$d'])), querySnapshot => {
                    saveData(querySnapshot);
                });

                firebaseRefList.push(subscriber);
            }

        }

        getStatistics();
        setIsLoading(false);

    }, [selectedDateFrom, selectedDateTo, completedOnly]);

    const saveData = (querySnapshot) => {
        const localGender = {
            Male: 0,
            Female: 0,
            Other: 0,
        };
        const localEdu = {
            elementary: 0,
            secondary: 0,
            postSub3: 0,
            post3to5: 0,
            postabove5: 0,
            phd: 0,
        }

        const localCountry = {};
        const localCity = {};

        const localAge = {};
        const localConn = {};
        const localRef = {};

        const localCombEdu = {
            Male: {
                elementary: 0,
                secondary: 0,
                postSub3: 0,
                post3to5: 0,
                postabove5: 0,
                phd: 0,
            },
            Female: {
                elementary: 0,
                secondary: 0,
                postSub3: 0,
                post3to5: 0,
                postabove5: 0,
                phd: 0,
            },
            Other: {
                elementary: 0,
                secondary: 0,
                postSub3: 0,
                post3to5: 0,
                postabove5: 0,
                phd: 0,
            },
        }
        const localCombCountry = {
            Male: {},
            Female: {},
            Other: {},
        }

        const localCombAge = {
            Male: {},
            Female: {},
            Other: {},
        }

        const localCombHkr = {
            Male: {},
            Female: {},
            Other: {},
        }

        const localCombRef = {
            Male: {},
            Female: {},
            Other: {},
        }

        for (let index = 0; index < querySnapshot.docs.length; index++) {
            const doc = querySnapshot.docs[index];

            let gender;
            let eduLevel;
            let age;

            let country;
            let region;
            let county;
            let city;

            let hkr;
            let ref;

            if (doc.data().gender != null) {
                gender = doc.data().gender;
                if (localGender[gender] == null) {
                    localGender[gender] = 1;
                }
                else {
                    localGender[gender] = localGender[gender] + 1;
                }
            }

            if (doc.data().education != null) {
                const index = Object.values(eduFields).indexOf(doc.data().education);
                if (index !== -1) {
                    eduLevel = Object.keys(eduFields).at(index);
                    if (localEdu[eduLevel] == null) {
                        localEdu[eduLevel] = 1;
                    }
                    else {
                        localEdu[eduLevel] = localEdu[eduLevel] + 1;
                    }
                }
            }

            if (doc.data().country != null) {
                country = doc.data().country;

                if (localCountry[country] == null) {
                    localCountry[country] = {};
                }

                if (localCountry[country].count != null) {
                    localCountry[country].count += 1;
                }
                else {
                    localCountry[country].count = 1;
                }

                if (gender != null) {
                    if (localCountry[country][gender] == null) {
                        localCountry[country][gender] = {};
                    }

                    if (localCountry[country][gender]?.count != null) {
                        localCountry[country][gender].count += 1
                    }
                    else {
                        localCountry[country][gender].count = 1;
                    }
                }

                if (country === "Sweden" && doc.data().region != null) {
                    region = doc.data().region;

                    if (localCountry[country].regions == null) {
                        localCountry[country].regions = {};
                    }

                    if (localCountry[country].regions[region] == null) {
                        localCountry[country].regions[region] = {};
                    }

                    if (localCountry[country].regions[region].count != null) {
                        localCountry[country].regions[region].count += 1;
                    }
                    else {
                        localCountry[country].regions[region].count = 1;
                    }

                    if (gender != null) {
                        if (localCountry[country].regions[region][gender] == null) {
                            localCountry[country].regions[region][gender] = {};
                        }

                        if (localCountry[country].regions[region][gender].count != null) {
                            localCountry[country].regions[region][gender].count += 1
                        }
                        else {
                            localCountry[country].regions[region][gender].count = 1;
                        }
                    }

                    if (region === "Skåne" && doc.data().county != null) {
                        county = doc.data().county;

                        if (localCountry[country].regions[region].county == null) {
                            localCountry[country].regions[region].county = {};
                        }

                        if (localCountry[country].regions[region].county[county] == null) {
                            localCountry[country].regions[region].county[county] = {};
                        }


                        if (localCountry[country].regions[region].county[county].count != null) {
                            localCountry[country].regions[region].county[county].count += 1;
                        }
                        else {
                            localCountry[country].regions[region].county[county].count = 1;
                        }

                        if (gender != null) {
                            if (localCountry[country].regions[region].county[county][gender] == null) {
                                localCountry[country].regions[region].county[county][gender] = {};
                            }

                            if (localCountry[country].regions[region].county[county][gender].count != null) {
                                localCountry[country].regions[region].county[county][gender].count += 1
                            }
                            else {
                                localCountry[country].regions[region].county[county][gender].count = 1;
                            }
                        }

                        if (doc.data().city != null) {
                            city = String(doc.data().city).toLocaleLowerCase().trim();

                            if (localCountry[country].regions[region].county[county].cities == null) {
                                localCountry[country].regions[region].county[county].cities = {};
                            }

                            if (localCountry[country].regions[region].county[county].cities[city] == null) {
                                localCountry[country].regions[region].county[county].cities[city] = {};
                            }

                            if (localCountry[country].regions[region].county[county].cities[city].count != null) {
                                localCountry[country].regions[region].county[county].cities[city].count += 1;
                            }
                            else {
                                localCountry[country].regions[region].county[county].cities[city].count = 1;
                            }

                            if (gender != null) {
                                if (localCountry[country].regions[region].county[county].cities[city][gender] == null) {
                                    localCountry[country].regions[region].county[county].cities[city][gender] = {};
                                }

                                if (localCountry[country].regions[region].county[county].cities[city][gender].count != null) {
                                    localCountry[country].regions[region].county[county].cities[city][gender].count += 1
                                }
                                else {
                                    localCountry[country].regions[region].county[county].cities[city][gender].count = 1;
                                }
                            }
                        }
                    }
                    else if (doc.data().city != null) {
                        city = String(doc.data().city).toLocaleLowerCase().trim();

                        if (localCountry[country].regions[region].cities == null) {
                            localCountry[country].regions[region].cities = {};
                        }

                        if (localCountry[country].regions[region].cities[city] == null) {
                            localCountry[country].regions[region].cities[city] = {};
                        }

                        if (localCountry[country].regions[region].cities[city].count != null) {
                            localCountry[country].regions[region].cities[city].count += 1;
                        }
                        else {
                            localCountry[country].regions[region].cities[city].count = 1;
                        }

                        if (gender != null) {
                            if (localCountry[country].regions[region].cities[city][gender] == null) {
                                localCountry[country].regions[region].cities[city][gender] = {};
                            }

                            if (localCountry[country].regions[region].cities[city][gender].count != null) {
                                localCountry[country].regions[region].cities[city][gender].count += 1
                            }
                            else {
                                localCountry[country].regions[region].cities[city][gender].count = 1;
                            }
                        }
                    }
                }
                else if (doc.data().city != null) {
                    city = String(doc.data().city).toLocaleLowerCase().trim();

                    if (localCountry[country].cities == null) {
                        localCountry[country].cities = {};
                    }

                    if (localCountry[country].cities[city] == null) {
                        localCountry[country].cities[city] = {};
                    }

                    if (localCountry[country].cities[city].count != null) {
                        localCountry[country].cities[city].count += 1;
                    }
                    else {
                        localCountry[country].cities[city].count = 1;
                    }

                    if (gender != null) {
                        if (localCountry[country].cities[city][gender] == null) {
                            localCountry[country].cities[city][gender] = {};
                        }

                        if (localCountry[country].cities[city][gender].count != null) {
                            localCountry[country].cities[city][gender].count += 1
                        }
                        else {
                            localCountry[country].cities[city][gender].count = 1;
                        }
                    }
                }
            }

            if (doc.data().city != null) {
                //console.log("City", doc.data())
                city = String(doc.data().city).toLocaleLowerCase().trim();
                if (localCity[city] != null) {
                    localCity[city] += 1;
                }
                else {
                    localCity[city] = 1;
                }
            }

            if (doc.data().hkrConnection != null) {
                hkr = doc.data().hkrConnection;
                if (localConn[hkr] != null) {
                    localConn[hkr] += 1;
                }
                else {
                    localConn[hkr] = 1;
                }
            }

            if (doc.data().reference != null) {
                const refList = String(doc.data().reference).split(';');
                ref = refList;

                refList.forEach((obj) => {
                    if (localRef[obj] != null) {
                        localRef[obj] += 1;
                    }
                    else {
                        localRef[obj] = 1;
                    }
                });

            }

            if (doc.data().dateOfBirth != null) {
                let dob = String(doc.data().dateOfBirth).split('-')[0];
                let current = new Date(Date.now()).getFullYear();


                age = Number(current) - Number(dob);
                //console.log("Age: ", age);
                if (age >= 0) {
                    if (localAge[age] != null) {
                        localAge[age] += 1;
                    }
                    else {
                        localAge[age] = 1;
                    }
                }
            }

            if (gender != null) {
                if (eduLevel != null) {
                    if (localCombEdu[gender] != null) {
                        if (localCombEdu[gender][eduLevel] != null) {
                            localCombEdu[gender][eduLevel] = localCombEdu[gender][eduLevel] + 1;
                        }
                        else {
                            localCombEdu[gender][eduLevel] = 1;
                        }
                    }
                    else {
                        localCombEdu[gender] = {
                            [eduLevel]: 1,
                        };
                    }
                }

                if (age != null) {
                    if (localCombAge['Male'][age] == null) {
                        localCombAge['Male'][age] = 0;
                    }

                    if (localCombAge[gender] != null) {
                        if (localCombAge[gender][age] != null) {
                            localCombAge[gender][age] = localCombAge[gender][age] + 1;
                        }
                        else {
                            localCombAge[gender][age] = 1;
                        }
                    }
                    else {
                        localCombAge[gender] = {
                            [age]: 1,
                        };
                    }
                }

                if (doc.data().country != null) {
                    country = doc.data().country;

                    if (localCombCountry[gender][country] == null) {
                        localCombCountry[gender][country] = {};
                    }

                    if (localCombCountry[gender][country].count != null) {
                        localCombCountry[gender][country].count += 1;
                    }
                    else {
                        localCombCountry[gender][country].count = 1;
                    }

                    if (country === "Sweden" && doc.data().region != null) {
                        region = doc.data().region;

                        if (localCombCountry[gender][country].regions == null) {
                            localCombCountry[gender][country].regions = {};
                        }

                        if (localCombCountry[gender][country].regions[region] == null) {
                            localCombCountry[gender][country].regions[region] = {};
                        }

                        if (localCombCountry[gender][country].regions[region].count != null) {
                            localCombCountry[gender][country].regions[region].count += 1;
                        }
                        else {
                            localCombCountry[gender][country].regions[region].count = 1;
                        }



                        if (region === "Skåne" && doc.data().county != null) {
                            county = doc.data().county;

                            if (localCombCountry[gender][country].regions[region].county == null) {
                                localCombCountry[gender][country].regions[region].county = {};
                            }

                            if (localCombCountry[gender][country].regions[region].county[county] == null) {
                                localCombCountry[gender][country].regions[region].county[county] = {};
                            }


                            if (localCombCountry[gender][country].regions[region].county[county].count != null) {
                                localCombCountry[gender][country].regions[region].county[county].count += 1;
                            }
                            else {
                                localCombCountry[gender][country].regions[region].county[county].count = 1;
                            }

                            if (doc.data().city != null) {
                                city = String(doc.data().city).toLocaleLowerCase().trim();

                                if (localCombCountry[gender][country].regions[region].county[county].cities == null) {
                                    localCombCountry[gender][country].regions[region].county[county].cities = {};
                                }

                                if (localCombCountry[gender][country].regions[region].county[county].cities[city] == null) {
                                    localCombCountry[gender][country].regions[region].county[county].cities[city] = {};
                                }

                                if (localCombCountry[gender][country].regions[region].county[county].cities[city].count != null) {
                                    localCombCountry[gender][country].regions[region].county[county].cities[city].count += 1;
                                }
                                else {
                                    localCombCountry[gender][country].regions[region].county[county].cities[city].count = 1;
                                }
                            }
                        }
                        else if (doc.data().city != null) {
                            city = String(doc.data().city).toLocaleLowerCase().trim();

                            if (localCombCountry[gender][country].regions[region].cities == null) {
                                localCombCountry[gender][country].regions[region].cities = {};
                            }

                            if (localCombCountry[gender][country].regions[region].cities[city] == null) {
                                localCombCountry[gender][country].regions[region].cities[city] = {};
                            }

                            if (localCombCountry[gender][country].regions[region].cities[city].count != null) {
                                localCombCountry[gender][country].regions[region].cities[city].count += 1;
                            }
                            else {
                                localCombCountry[gender][country].regions[region].cities[city].count = 1;
                            }
                        }
                    }
                    else if (doc.data().city != null) {
                        city = String(doc.data().city).toLocaleLowerCase().trim();

                        if (localCombCountry[gender][country].cities == null) {
                            localCombCountry[gender][country].cities = {};
                        }

                        if (localCombCountry[gender][country].cities[city] == null) {
                            localCombCountry[gender][country].cities[city] = {};
                        }

                        if (localCombCountry[gender][country].cities[city].count != null) {
                            localCombCountry[gender][country].cities[city].count += 1;
                        }
                        else {
                            localCombCountry[gender][country].cities[city].count = 1;
                        }
                    }
                }

                if (hkr != null) {
                    if (localCombHkr['Male'][hkr] == null) {
                        localCombHkr['Male'][hkr] = 0;
                    }

                    if (localCombHkr[gender] != null) {
                        if (localCombHkr[gender][hkr] != null) {
                            localCombHkr[gender][hkr] = localCombHkr[gender][hkr] + 1;
                        }
                        else {
                            localCombHkr[gender][hkr] = 1;
                        }
                    }
                    else {
                        localCombHkr[gender] = {
                            [hkr]: 1,
                        };
                    }
                }

                if (ref != null) {
                    if (localCombRef[gender] != null) {
                        ref.forEach((obj) => {
                            if (localCombRef['Male'][obj] == null) {
                                localCombRef['Male'][obj] = 0;
                            }

                            if (localCombRef[gender][obj] != null) {
                                localCombRef[gender][obj] = localCombRef[gender][obj] + 1;
                            }
                            else {
                                localCombRef[gender][obj] = 1;
                            }
                        })
                    }
                    else {
                        localCombRef[gender] = {};
                        ref.forEach((obj) => {
                            if (localCombRef['Male'] == null || localCombRef['Male'][obj] == null) {
                                localCombRef['Male'][obj] = 0;
                            }

                            if (localCombRef[gender][obj] != null) {
                                localCombRef[gender][obj] = localCombRef[gender][obj] + 1;
                            }
                            else {
                                localCombRef[gender][obj] = 1;
                            }
                        });
                    }
                }
            }
        }

        setAgeData(localAge);
        setCountryData(localCountry);
        setHkrData(localConn);
        setRefData(localRef);
        setGenderData(localGender);
        setEduData(localEdu);

        setCombDataEdu(localCombEdu);
        setCombDataAge(localCombAge);
        setCombDataCountry(localCombCountry);
        setCombDataHkr(localCombHkr);
        setCombDataRef(localCombRef);
    }

    const renderTable = () => {
        switch (category) {
            case 'gen':
                return (
                    <GenderGraph isLoading={isLoading} genderData={genderData} />
                );
            case 'edu':
                return (
                    <EducationGraph isLoading={isLoading} educationData={eduData} educationDataGender={combDataEdu} splitByGender={gender} />
                );
            case 'city':
                return (
                    <RegionGraph isLoading={isLoading} regionData={countryData} regionDataGender={combDataCountry} splitByGender={gender} />
                );
            case 'age':
                return (
                    <AgeGraph isLoading={isLoading} ageData={ageData} ageDataGender={combDataAge} splitByGender={gender} />
                );
            case 'hkr':
                return (
                    <HKRConnGraph isLoading={isLoading} hkrConnData={hkrData} hkrConnDataGender={combDataHkr} splitByGender={gender} />
                );
            case 'ref':
                return (
                    <ReferenceGraph isLoading={isLoading} refData={refData} refDataGender={combDataRef} splitByGender={gender} />
                );
            default:
                return (
                    <Box>
                        
                    </Box>
                )
        }
    }

    return (
        <div>
            <Stack justifyContent={"center"} style={{ marginBottom: '40px' }}>
                <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent={'left'}>
                    <Grid2 size={{ xs: 2, sm: 4, md: 4 }}>

                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', justifyContent: 'left' }}>
                                Sort data by:
                            </div>
                            <FormGroup>
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        style={{ width: "220px" }}
                                        value={category}
                                        label="Category"
                                        onChange={handleChangeCategory}
                                    >
                                        <MenuItem value={''}></MenuItem>
                                        <MenuItem value={'gen'}>Gender</MenuItem>
                                        <MenuItem value={'edu'}>Education Level</MenuItem>
                                        <MenuItem value={'city'}>Region</MenuItem>
                                        <MenuItem value={'age'}>Age</MenuItem>
                                        <MenuItem value={'hkr'}>Connection to HKR</MenuItem>
                                        <MenuItem value={'ref'}>Reference</MenuItem>
                                    </Select>
                                </FormControl>

                                {category !== "" && category !== 'gen' &&
                                    <FormControlLabel
                                        style={{ paddingTop: "10px" }}
                                        control={
                                            <Checkbox checked={gender} onChange={handleChange} name="gender" />
                                        }
                                        label="Gender"
                                    />}

                                <FormControlLabel
                                    control={
                                        <Checkbox checked={completedOnly} onChange={() => { setCompletedOnly(!completedOnly)}} name="education" />
                                    }
                                    label="Completed Signup only"
                                />
                                {/* 
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={education} onChange={handleChange} name="education" />
                                    }
                                    label="Education"
                                />*/}
                            </FormGroup>
                        </FormControl>
                    </Grid2>

                    <Grid2 size={{ xs: 2, sm: 4, md: 4 }}>
                        <div style={{ marginTop: '35px', marginBottom: '10px', display: 'flex', justifyContent: 'left' }}>
                            Timespan (User registrered):
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack alignItems={"center"} direction={'row'} spacing={2}>
                                <DatePicker
                                    label="From"
                                    value={selectedDateFrom}
                                    disableFuture
                                    format={'YYYY-MM-DD'}
                                    onChange={handleDateChangeFrom}
                                    slotProps={{ field: { clearable: true, onClear: () => setSelectedDateFrom(null) }, textField: { size: 'small', sx: { width: 220 }, error: false } }}
                                />
                                <div style={{ marginTop: '5px', marginBottom: '5px', display: 'flex', justifyContent: 'left' }}>
                                    <HorizontalRuleRoundedIcon />
                                </div>
                                <DatePicker
                                    label="To"
                                    value={selectedDateTo}
                                    disableFuture
                                    format={'YYYY-MM-DD'}
                                    onChange={handleDateChangeTo}
                                    slotProps={{ field: { clearable: true, onClear: () => setSelectedDateTo(null) }, textField: { size: 'small', sx: { width: 220 }, error: false } }}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid2>
                </Grid2>
            </Stack>

            <hr />
            <Box sx={{ marginTop: '40px', width: '60%', justifyContent: "center" }}>
                {renderTable()}
            </Box>
        </div>
    )
}