import { useMemo } from "react";
import { CircularProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip } from 'recharts';
import { eduFields, eduFieldsShort, genderFields } from "../helpFunctions";

export const EducationGraph = ({ isLoading, educationData, educationDataGender, splitByGender }) => {
    const chartData = useMemo(() => {
        let data = []

        if (splitByGender === true) {
            Object.keys(educationDataGender['Male']).forEach((eduLevel) => {
                data.push({ name: eduFieldsShort[eduLevel], male: educationDataGender['Male'][eduLevel], female: educationDataGender['Female'][eduLevel], other: educationDataGender['Other'][eduLevel] });
            })
        }
        else {
            Object.keys(educationData).forEach((key) => {
                data.push({ name: eduFieldsShort[key], count: educationData[key] });
            })
        }

        return data;
    }, [educationData, educationDataGender, splitByGender]);

    function createRow(eduField) {
        const data = [];
        data.push(eduFields[eduField]);

        Object.keys(educationDataGender).forEach((gender) => {
            if (educationDataGender[gender] != null && educationDataGender[gender][eduField] != null) {
                data.push(educationDataGender[gender][eduField]);
            }
            else {
                data.push(0);
            }
        });

        return data;
    }

    return (
        <>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            ) : (
                <Stack spacing={5}>
                    <BarChart
                        width={700}
                        height={400}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {splitByGender === true ? (
                            <>
                                <Bar dataKey="male" fill="#28a745" />
                                <Bar dataKey="female" fill="#fd7e14" />
                                <Bar dataKey="other" fill="#6610f2" />
                            </>
                        ) : (
                            <>
                                <Bar dataKey="count" fill="#007bff" />
                            </>
                        )}
                    </BarChart>

                    <Table
                        sx={{ justifyContent: 'center' }}
                        aria-labelledby="statsTable"
                        aria-label="Stats Table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Education Level
                                </TableCell>
                                {splitByGender === true ? (
                                    <>
                                        {Object.keys(genderFields).map((gender) => {
                                            return (
                                                <TableCell key={gender}>
                                                    {genderFields[gender]}
                                                </TableCell>);
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <TableCell key={"userHeader"}>Number of users</TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {splitByGender === true ? (
                                <>
                                    {Object.keys(eduFields).map((field) => {
                                        return (
                                            <TableRow key={field}>
                                                {createRow(field).map((value, index) => {
                                                    return (
                                                        <TableCell key={field + "-" + value + "-" + index}>
                                                            {value}
                                                        </TableCell>);
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    {Object.keys(educationData).map((eduLevel, index) => {
                                        return (
                                            <TableRow key={eduLevel + "row"}>
                                                <TableCell key={"eduField-" + eduLevel + "-" + index}>
                                                    {eduFields[eduLevel]}
                                                </TableCell>
                                                <TableCell key={"eduData-" + eduLevel + "-" + index}>
                                                    {educationData[eduLevel]}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </>
                            )}

                        </TableBody>
                    </Table>
                </Stack>
            )}
        </>
    )
}