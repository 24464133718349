import { Box, Divider, Grid, Grid2, Stack } from "@mui/material"
import { useAuth } from "../../../contexts/AuthContext";
import { useMemo } from "react";
import { ExtendedCompanyQuestionsEng, ExtendedCompanyQuestionsSwe } from "../../../constants/companyQuestions/companyQuestions";


export const OwnershipDisplayComponent = ({ answerData, listVariant = false }) => {
    const { currentUser } = useAuth();

    const CompOwnershipQuestion = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return ExtendedCompanyQuestionsSwe.compOwnership;
        }
        else {
            return ExtendedCompanyQuestionsEng.compOwnership;
        }
    }, [currentUser]);


    const displayOwnerType = (key) => {
        if (key != null) {
            let ownerType = CompOwnershipQuestion.ownerTypeOptions.find(element => element.value === key);

            if (ownerType != null) {
                return ownerType.label;
            }
        }

        return key;
    }

    return (
        <Box sx={listVariant ? { paddingX: '15px', paddingTop: '10px' } : {}}>
            <Grid2 container direction={'row'} spacing={1}>
                <Grid2 size={{ xs: 4 }}>
                    <Box fontWeight={700}>{CompOwnershipQuestion.ownerTypeLabel}</Box>
                </Grid2>
                <Grid2 size={{ xs: 4 }}>
                    <Box fontWeight={700}>{CompOwnershipQuestion.ownerNameLabel}</Box>
                </Grid2>
                <Grid2 size={{ xs: 2 }}>
                    <Box fontWeight={700}>{CompOwnershipQuestion.ownershipLabel}</Box>
                </Grid2>
                <Grid2 size={{ xs: 1 }}>
                    <Box fontWeight={700}>{CompOwnershipQuestion.ownerTimeComittmentLabel}</Box>
                </Grid2>
            </Grid2>
            <Divider className="thick blue" sx={{ marginTop: '3px' }} />
            {answerData?.map((item, index) => (
                <Box key={index} marginBottom={'3px'}>
                    <Grid2 container direction={'row'} spacing={1}>
                        <Grid2 size={{ xs: 4 }}>
                            <Box>{displayOwnerType(item.ownerType)}</Box>
                        </Grid2>
                        <Grid2 size={{ xs: 4 }}>
                            <Box>{item.text}</Box>
                        </Grid2>
                        <Grid2 size={{ xs: 2 }}>
                            <Box>{item.procent} %</Box>
                        </Grid2>
                        <Grid2 size={{ xs: 1 }}>
                            {item?.ownerType === 'privateFounder' || item?.ownerType === 'compFounder' ? (
                                <>
                                    {item.comittment != null ? (
                                        <Box>{item.comittment} %</Box>
                                    ) : (
                                        <Box color={'red'}>Not set</Box>
                                    )}
                                </>

                            ) : ("")}
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ marginTop: '3px' }} />
                </Box>
            ))}
        </Box>
    )
}