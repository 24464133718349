import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TemplateEditor from './TemplateEditor';
import ContentCreator from './ContentCreator';
import { useLocation } from 'react-router-dom'
import { ReactComponent as AssembledBricks } from '../../assets/images/krinova__AssembledBricks_line3_colour1.svg';
import { ReactComponent as SingleBrick } from '../../assets/images/krinova__SingleBrick_line3_colour1.svg';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useState } from 'react';

export default function TabMenuContentTool() {
    const location = useLocation()
    const selectedTab = location.hash;

    const [value, setValue] = useState(getHashValueInit(selectedTab));

    getHashValue(selectedTab);

    //Reading the initial value from the hash-value
    function getHashValueInit(hashVal) {
        switch (hashVal) {
            case '#ContentTool-create':
                return '1';
            case '#ContentTool-manage':
                return '2';
            default:
                return '1';
        }
    }


    function getHashValue(hashVal) {
        switch (hashVal) {
            case '#ContentTool-create':
                if (value !== '1') {
                    setValue('1');
                }
                break;
            case '#ContentTool-manage':
                if (value !== '2') {
                    setValue('2');
                }
                break;
            default:
                if (value !== '1') {
                    setValue('1');
                }
                break;
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box>
                    <TabList onChange={handleChange} aria-label="tab nav content creator">
                        <Tab icon={<SingleBrick style={{ width: "40px", height: "fit-content" }} />} iconPosition="start" label="Content Creator" value="1" href={window.location.pathname + "#ContentTool-create"} />
                        <Tab icon={<AssembledBricks style={{ width: "30px", height: "fit-content" }} />} iconPosition="start" label="Content Manager" value="2" href={window.location.pathname + "#ContentTool-manage"} />
                    </TabList>
                </Box>

                <TabPanel value="1">
                    <ContentCreator />
                </TabPanel>
                <TabPanel value="2">
                    <TemplateEditor />
                </TabPanel>


            </TabContext>
        </Box>
    );
}
