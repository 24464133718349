import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Grid2, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { collection, onSnapshot, query, QuerySnapshot, where } from "firebase/firestore";
import { useEffect, useState } from "react";

import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import firebaseRefList from "../../../firebase/firebaseRefList";
import { db } from "../../../firebase";
import { useGetAllCompanyInfo } from "../../../hooks/projectData/useGetAllCompanyInfo";
import { useGetAllEntryQuestionAnswers } from "../../../hooks/projectData/useGetAllEntryQuestionAnswers";
import { FoodConnGraph } from "./Graphs/IBOs/FoodConnGraph";
import { CompanyChoices } from "../../../constants/labels/labels";
import { RegCompGraph } from "./Graphs/IBOs/RegCompGraph";
import { useGetAllIBOUsers } from "../../../hooks/projectData/useGetAllIBOUsers";
import { FemaleRepGraph } from "./Graphs/IBOs/FemaleRepGraph";
import { HKRConnIBOGraph } from "./Graphs/IBOs/HKRConnIBOGraph";
import { RegionIBOGraph } from "./Graphs/IBOs/RegionIBOGraph";

export const StatsGraphsIBO = () => {
    const [isLoading, setIsLoading] = useState(false);
    const compInfoList = useGetAllCompanyInfo();
    const entryQuestionList = useGetAllEntryQuestionAnswers();
    const iboUserList = useGetAllIBOUsers();
    const [category, setCategory] = useState('');
    const [activeOnly, setActiveOnly] = useState(true);

    const [selectedDateFrom, setSelectedDateFrom] = useState(null);
    const [selectedDateTo, setSelectedDateTo] = useState(null);

    const [foodConnData, setFoodConnData] = useState(Object);
    const [regCompData, setRegCompata] = useState(Object);
    const [femaleRepData, setFemaleRepData] = useState(Object);
    const [hkrConnData, setHkrConnData] = useState(Object);
    const [regionData, setRegionData] = useState(Object);

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    const handleDateChangeFrom = (newValue) => {
        if (newValue != null) {
            setSelectedDateFrom(newValue);

        } else {
            setSelectedDateFrom(null)
        }
    };

    const handleDateChangeTo = (newValue) => {
        if (newValue != null) {
            setSelectedDateTo(newValue);

        } else {
            setSelectedDateTo(null)
        }
    };


    useEffect(() => {

        const getStatistics = async () => {
            setIsLoading(true);

            if ((selectedDateFrom == null || selectedDateFrom === "") && (selectedDateTo == null || selectedDateTo === "")) {
                const subscriber = onSnapshot(query(collection(db, 'Projects')), querySnapshot => {
                    saveData(querySnapshot);
                });

                firebaseRefList.push(subscriber);
            }
            else if ((selectedDateFrom == null || selectedDateFrom === "") && selectedDateTo != null && selectedDateTo !== "") {
                const subscriber = onSnapshot(query(collection(db, 'Projects'), where("created", "<=", selectedDateTo['$d'])), querySnapshot => {
                    saveData(querySnapshot);
                });

                firebaseRefList.push(subscriber);
            }
            else if (selectedDateFrom != null && selectedDateFrom !== "" && (selectedDateTo == null || selectedDateTo === "")) {
                const subscriber = onSnapshot(query(collection(db, 'Projects'), where("created", ">=", selectedDateFrom['$d'])), querySnapshot => {
                    saveData(querySnapshot);
                });

                firebaseRefList.push(subscriber);
            }
            else if (selectedDateFrom != null && selectedDateFrom !== "" && selectedDateTo != null && selectedDateTo !== "") {
                const subscriber = onSnapshot(query(collection(db, 'Projects'), where("created", "<=", selectedDateTo['$d']), where("created", ">=", selectedDateFrom['$d'])), querySnapshot => {
                    saveData(querySnapshot);
                });

                firebaseRefList.push(subscriber);
            }
        }

        if (Object.keys(compInfoList || {}).length > 0 && Object.keys(entryQuestionList || {}).length > 0 && Object.keys(iboUserList || {}).length > 0) {
            setIsLoading(true);
            getStatistics();
            setIsLoading(false);
        }

    }, [selectedDateFrom, selectedDateTo, activeOnly, compInfoList, entryQuestionList, iboUserList]);

    const saveData = (/** @type {QuerySnapshot<import("firebase/firestore").DocumentData, import("firebase/firestore").DocumentData>} */ querySnapshot) => {
        //console.log("DataSnap: ", querySnapshot.docs)

        let localFoodConn = {
            yes: 0,
            no: 0,
        }

        let localFemRep = {
            yes: 0,
            no: 0,
        }

        let localHKRConn = {
            yes: 0,
            no: 0,
        }

        let localHasComp = {
            no: 0,
            yes_new: 0,
            yes_established: 0
        }

        let localRegion = {
            northEastScania: 0,
            scania: 0,
            sweden: 0,
            other: 0,
        }

        for (let index = 0; index < querySnapshot.docs.length; index++) {
            const project = querySnapshot.docs[index];
            const compInfo = compInfoList[project.id];
            const entryQuestions = entryQuestionList[project.id];
            const iboUsers = [];

            let closestRegion;

            if (project.data().IBOOwner != null) {
                for (let index = 0; index < project.data().IBOOwner.length; index++) {
                    const userID = project.data().IBOOwner[index];

                    const userInfo = iboUserList[userID];

                    if (userInfo != null) {
                        iboUsers.push(userInfo);
                    }
                }

                if (iboUsers.length > 0) {
                    let hasFemaleRep = false;
                    let hasHKRConn = false;

                    for (let index = 0; index < iboUsers.length; index++) {
                        const userData = iboUsers[index];

                        if (userData?.gender === 'Female') {
                            hasFemaleRep = true;
                        }

                        if (userData?.hkrConnection != null && userData?.hkrConnection !== '' && userData?.hkrConnection !== 'None') {
                            hasHKRConn = true;
                        }

                        if (userData?.country === 'Sweden' || userData?.country == null) {
                            if (userData?.region === 'Skåne') {
                                if (userData?.county === 'Kristianstad' || userData?.county === 'Hässleholm' || userData?.county === 'Östra Göinge' || userData?.county === 'Osby' || userData?.county === 'Bromölla' || userData?.county === 'Hörby') {
                                    closestRegion = 1;
                                }
                                else {
                                    if (closestRegion > 2 || closestRegion == null){
                                        closestRegion = 2;
                                    }
                                }
                            }
                            else if (userData?.region != null) {
                                if (closestRegion > 3 || closestRegion == null){
                                    closestRegion = 3;
                                }
                            }
                        }
                        else if (userData?.country != null) {
                            if (closestRegion == null){
                                closestRegion = 4;
                            }
                        }
                    }

                    if (hasFemaleRep === true) {
                        localFemRep.yes += 1;
                    }
                    else {
                        localFemRep.no += 1;
                    }

                    if (hasHKRConn === true) {
                        localHKRConn.yes += 1;
                    }
                    else {
                        localHKRConn.no += 1;
                    }
                }
            }

            if (entryQuestions != null) {
                if (entryQuestions?.foodConnection != null) {
                    if (localFoodConn[entryQuestions?.foodConnection?.answer] != null) {
                        localFoodConn[entryQuestions?.foodConnection?.answer] += 1;
                    }
                    else {
                        localFoodConn[entryQuestions?.foodConnection?.answer] = 1;
                    }
                }
            }

            if (project.data()?.existingComp != null) {
                if (project.data()?.existingComp === 'no' || project.data()?.existingComp === CompanyChoices['no']) {
                    localHasComp.no += 1;
                }
                else if (project.data()?.existingComp === 'yes_new' || project.data()?.existingComp === CompanyChoices['yes_new']) {
                    localHasComp.yes_new += 1;
                }
                else if (project.data()?.existingComp === 'yes_established' || project.data()?.existingComp === CompanyChoices['yes_established']) {
                    localHasComp.yes_established += 1;
                }
            }

            if (compInfo != null) {
                if (String(compInfo?.compCity).includes(';') === true) {
                    let cityData = String(compInfo?.compCity).split(';');
                    let countyData;
                    let regionData;

                    if (cityData[0] != null && cityData[0] !== "") {
                        countyData = cityData[0];
                    }
                    if (cityData[1] != null && cityData[1] !== "") {
                        regionData = cityData[1];
                    }
    
                    if (countyData != null && regionData === 'Skåne'){
                        if (countyData === 'Kristianstad' || countyData === 'Hässleholm' || countyData === 'Östra Göinge' || countyData === 'Osby' || countyData === 'Bromölla' || countyData === 'Hörby') {
                            closestRegion = 1;
                        }
                        else {
                            if (closestRegion > 2 || closestRegion == null){
                                closestRegion = 2;
                            }
                        }
                    }
                    else if (regionData != null &&  regionData !== 'Skåne' && regionData !== 'Outside Sweden'){
                        if (closestRegion > 3 || closestRegion == null){
                            closestRegion = 3;
                        }
                    }
                    else if (regionData === 'Outside Sweden'){
                        if (closestRegion == null){
                            closestRegion = 4;
                        }
                    }
                }
            }

            if (closestRegion === 1){
                localRegion.northEastScania += 1;
            }
            else if (closestRegion === 2){
                localRegion.scania += 1;
            }
            else if (closestRegion === 3){
                localRegion.sweden += 1;
            }
            else if (closestRegion === 4){
                localRegion.other += 1;
            }
        }

        setFoodConnData(localFoodConn);
        setRegCompata(localHasComp);
        setFemaleRepData(localFemRep);
        setHkrConnData(localHKRConn);
        setRegionData(localRegion);
    }


    const renderTable = () => {
        switch (category) {
            case 'food':
                return (
                    <FoodConnGraph isLoading={isLoading} foodConnData={foodConnData} />
                );
            case 'femRep':
                return (
                    <FemaleRepGraph isLoading={isLoading} femaleRepData={femaleRepData} />
                );
            case 'hkr':
                return (
                    <HKRConnIBOGraph isLoading={isLoading} hkrConnData={hkrConnData} />
                );
            case 'regComp':
                return (
                    <RegCompGraph isLoading={isLoading} regCompData={regCompData} />
                );
            case 'region':
                return (
                    <RegionIBOGraph isLoading={isLoading} regionData={regionData} />
                );
            default:
                return (
                    <Box>

                    </Box>
                )
        }
    }


    return (
        <div>
            <Stack justifyContent={"center"} style={{ marginBottom: '40px' }}>
                <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent={'left'}>
                    <Grid2 size={{ xs: 2, sm: 4, md: 4 }}>

                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', justifyContent: 'left' }}>
                                Sort data by:
                            </div>
                            <FormGroup>
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        style={{ width: "220px" }}
                                        value={category}
                                        label="Category"
                                        onChange={handleChangeCategory}
                                    >
                                        <MenuItem value={''}></MenuItem>
                                        <MenuItem value={'food'}>Food connection</MenuItem>
                                        <MenuItem value={'femRep'}>Female representation</MenuItem>
                                        <MenuItem value={'hkr'}>Connection to HKR</MenuItem>
                                        <MenuItem value={'regComp'}>Registered company</MenuItem>
                                        <MenuItem value={'region'}>Region</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControlLabel
                                    control={
                                        <Checkbox checked={activeOnly} onChange={() => { setActiveOnly(!activeOnly) }} name="activeIBO" />
                                    }
                                    label="Active only"
                                />
                                {/* 
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={education} onChange={handleChange} name="education" />
                                    }
                                    label="Education"
                                />*/}
                            </FormGroup>
                        </FormControl>
                    </Grid2>

                    <Grid2 size={{ xs: 2, sm: 4, md: 4 }}>
                        <div style={{ marginTop: '35px', marginBottom: '10px', display: 'flex', justifyContent: 'left' }}>
                            Timespan (IBO created):
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack alignItems={"center"} direction={'row'} spacing={2}>
                                <DatePicker
                                    label="From"
                                    value={selectedDateFrom}
                                    disableFuture
                                    format={'YYYY-MM-DD'}
                                    onChange={handleDateChangeFrom}
                                    slotProps={{ field: { clearable: true, onClear: () => setSelectedDateFrom(null) }, textField: { size: 'small', sx: { width: 220 }, error: false } }}
                                />
                                <div style={{ marginTop: '5px', marginBottom: '5px', display: 'flex', justifyContent: 'left' }}>
                                    <HorizontalRuleRoundedIcon />
                                </div>
                                <DatePicker
                                    label="To"
                                    value={selectedDateTo}
                                    disableFuture
                                    format={'YYYY-MM-DD'}
                                    onChange={handleDateChangeTo}
                                    slotProps={{ field: { clearable: true, onClear: () => setSelectedDateTo(null) }, textField: { size: 'small', sx: { width: 220 }, error: false } }}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid2>
                </Grid2>
            </Stack>

            <hr />
            <Box sx={{ marginTop: '40px', width: '60%', justifyContent: "center" }}>
                {renderTable()}
            </Box>
        </div>
    )
}