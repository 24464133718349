import { useState } from 'react'
import '../../assets/scss/app.scss'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { rectifyQuestion, writeNote, deleteNote } from '../../firebase/cloudFunctions';
import Stack from '@mui/material/Stack'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { useAuth } from '../../contexts/AuthContext'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import format from 'date-fns/format'

const CommentsQuestionExternal = ({ project, projID, questionKey, step, location, comments }) => {
    const { currentUser } = useAuth()

    //define `isLoading` state and the function to change it. The value is false by default
    const [isLoading, setIsLoading] = useState(false);

    //define `contacForm` state and the function to change it
    //the whole form is represented by an object, with nested objects inside that represent the input fields
    const [commentsForm, setCommentsForm] = useState({

        commentField: {
            value: '',
            //location: 'External',
            elementConfig: {
                //required: false,
                size: 'small',
                id: 'standard-comment',
                className: 'form-control-external-textfield',
                label: 'Comment',
                multiline: true,
            },

            blur: false,
        },
    });

    //convert the `contactForm` object into an array
    const formElementsArray = [];
    for (let key in commentsForm) {
        formElementsArray.push({
            id: key,
            ...commentsForm[key],
        });
    }

    //map the array to return an array of JSX elements
    const formElements = formElementsArray.map((element) => {
        return (
            <Box key={element.id}>
                <TextField
                    {...element.elementConfig}
                    error={!element.valid && element.blur}
                    helperText={
                        !element.valid && element.blur
                            ? element.validation.errorMessage
                            : null
                    }
                    onChange={(event) => inputChangedHandler(event, element.id)}
                    onBlur={(event) => inputChangedHandler(event, element.id)}
                    value={element.value}
                ></TextField>
            </Box>
        );
    });

    //this function runs when an input changes or is blurred
    const inputChangedHandler = (event, inputIdentifier) => {
        //create a new object representing the input that was changed or blurred
        const updatedFormElement = {
            ...commentsForm[inputIdentifier],
            value: event.target.value,
            //blur: event.type === 'blur' ? true : false,
            touched: true,
        };

        //create a new object represeting the whole form object
        const updatedCommentsForm = {
            ...commentsForm,
            [inputIdentifier]: updatedFormElement,
        };

        //the whole form is valid until it's not
        let formIsValid = true;
        for (let inputElementIdentifier in updatedCommentsForm) {
            formIsValid =
                updatedCommentsForm[inputElementIdentifier].valid && formIsValid;
        }

        //update `contactForm` state
        setCommentsForm(updatedCommentsForm);


    };

    // //this function is called from inside `inputChangedHandler()`, and checks the validity of an input field
    // const checkValidity = (value, rules) => {
    //     //it's always true until there's one false in the way
    //     let isValid = true;
    //     if (rules.required) {
    //         //value.trim() gets rid of white spaces
    //         isValid = value.trim() !== '' && isValid;
    //     }

    //     return isValid;
    // };

    const submitComment = async () => {
        //set `isLoading` state to true, so the spinner is rendered
        setIsLoading(true);

        // call the cloud function. 
        writeNote({
            projectID: projID,
            assignedID: questionKey,
            location: location,
            step: step,
            isExternal: true,
            message: commentsForm.commentField.value,
        })

            //the code inside the `then()` block runs when the message was successfully sent from inside the cloud function
            .then(() => {

                //create a new `contactForm` object that looks like the original `contactForm` state
                let originalCommentsForm = {};
                for (let key in commentsForm) {
                    originalCommentsForm[key] = {
                        ...commentsForm[key],
                        value: '',
                        valid: key === 'honeypot' ? true : false,
                        blur: false,
                    };
                }


                //reset `contactForm` state to its original value
                setCommentsForm(originalCommentsForm);


                //set `isLoading` state to false, so the spinner is not rendered anymore
                setIsLoading(false);

                // //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                // setOpenSnackbar(true);
            })
            //this code below runs when the message was NOT successfully sent from inside of the cloud function
            .catch(() => {
                // //set `error` state to true
                // setError(true);

                //set `isLoading` state to false, so the spinner is not rendered anymore
                setIsLoading(false);

                // //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                // setOpenSnackbar(true);
            });
    }

    const [rectify, setRectify] = useState('');
    const handleRectify = (event) => {
        setRectify(event.target.value);

    };

    const submitRectifyComment = async () => {
        //set `isLoading` state to true, so the spinner is rendered
        setIsLoading(true);


        // call the cloud function. 
        rectifyQuestion({
            projectID: projID,
            assignedID: questionKey,
            location: location,
            step: step,
            approved: rectify,
            isExternal: true,
            comment: commentsForm.commentField.value,
        })

            //the code inside the `then()` block runs when the message was successfully sent from inside the cloud function
            .then(() => {

                //create a new `contactForm` object that looks like the original `contactForm` state
                let originalCommentsForm = {};
                for (let key in commentsForm) {
                    originalCommentsForm[key] = {
                        ...commentsForm[key],
                        value: '',
                        valid: key === 'honeypot' ? true : false,
                        blur: false,
                    };
                }


                //reset `contactForm` state to its original value
                setCommentsForm(originalCommentsForm);


                //set `isLoading` state to false, so the spinner is not rendered anymore
                setIsLoading(false);

                // //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                // setOpenSnackbar(true);
            })
            //this code below runs when the message was NOT successfully sent from inside of the cloud function
            .catch(() => {
                // //set `error` state to true
                // setError(true);

                //set `isLoading` state to false, so the spinner is not rendered anymore
                setIsLoading(false);

                // //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                // setOpenSnackbar(true);
            });
    }

    const deleteComment = async (comments) => {
        setIsLoading(true);

        // call the cloud function. 
        deleteNote({
            messageID: comments.id,
            projectID: project.id,
            isExternal: true,
        }).then(() => {
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    };


    return (
        <>
            <Box sx={{
                width: 800,
                maxWidth: '100%',
            }}>

                <div>
                    {comments.map(comments =>
                        <Box>
                            <span className="comment_username">{comments.createdUserName} </span> <span>{format(new Date(comments.created.seconds * 1000), 'yyyy-MM-dd HH:mm')}</span>
                            <p>{comments.message}

                                {currentUser && currentUser.uid === comments.createdBy && (
                                    <HighlightOffOutlinedIcon
                                        fontSize="small"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            deleteComment(comments);
                                        }}
                                    />

                                )}</p>

                        </Box>
                    )}

                </div>

                {/* {isLoading ? (
                    <Box sx={{ width: 'auto', margin: '1rem' }}>
                        <LinearProgress />
                    </Box>

                ) : ( */}
                {currentUser.role >= 2 ? (
                    <form className="comment_form_external">
                        <Stack spacing={0} direction="row">
                            {formElements}
                            <FormControl size="small" required sx={{ ml: 1, mr: 1, minWidth: 160 }}>
                                <InputLabel id="select-feedback-type">Feeback type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={rectify}
                                    label="Feedback type *"
                                    onChange={handleRectify}
                                >
                                    <MenuItem value="">
                                        <em>Please select</em>
                                    </MenuItem>
                                    <MenuItem value={false}>IBO action needed</MenuItem>
                                    <MenuItem value={true}>No IBO action needed</MenuItem>

                                </Select>
                                <FormHelperText>Required</FormHelperText>
                            </FormControl>
                            <Button size="small" className="post_comment_btn_external" variant="contained"
                                color="primary"
                                disabled={isLoading || rectify === "" || commentsForm.commentField.value === ""}
                                onClick={submitRectifyComment}
                            >Post</Button></Stack>
                    </form>
                ) : ("")}

                {/* )
                } */}
            </Box>


        </>
    )
}

export default CommentsQuestionExternal
