export const eduFields = {
    elementary: "Elementary School",
    secondary: "Secondary / High School",
    postSub3: "Post secondary education < 3 years",
    post3to5: "Post secondary education 3-5 years",
    postabove5: "Post secondary education > 5 years",
    phd: "PhD",
}

export const eduFieldsShort = {
    elementary: "Elem.School",
    secondary: "Sec/High School",
    postSub3: "Post sec. < 3 years",
    post3to5: "Post sec. 3-5 years",
    postabove5: "Post sec. > 5 years",
    phd: "PhD",
}

export const genderFields = {
    male: "Male",
    female: "Female",
    other: "Other"
}

export const regionGroupLabels = {
    northEastScania: 'Skåne Nordost',
    scania: 'Rest of Skåne',
    sweden: 'Rest of Sweden',
    other: 'Other',
}

export const regionGroups = {
    northEastScania: [
        "Kristianstad",
        "Hässleholm",
        "Östra Göinge",
        "Osby",
        "Bromölla",
        "Hörby"
    ],
    scania: [
        "Bjuv",
        "Burlöv",
        "Båstad",
        "Eslöv",
        "Helsingborg",
        "Höganäs",
        "Höör",
        "Klippan",
        "Kävlinge",
        "Landskrona",
        "Lomma",
        "Lund",
        "Malmö",
        "Perstorp",
        "Simrishamn",
        "Sjöbo",
        "Skurup",
        "Staffanstorp",
        "Svalöv",
        "Svedala",
        "Tomelilla",
        "Trelleborg",
        "Vellinge",
        "Ystad",
        "Åstorp",
        "Ängelholm",
        "Örkelljunga",
    ],
    sweden: [
        "Stockholm",
        "Uppsala",
        "Sörmland",
        "Östergötland",
        "Jönköping Län",
        "Kronoberg",
        "Kalmar Län",
        "Gotland",
        "Blekinge",
        "Halland",
        "Västra Götaland",
        "Värmland",
        "Örebro Län",
        "Västmanland",
        "Dalarna",
        "Gävleborg",
        "Västernorrland",
        "Jämtland Härjedalen",
        "Västerbotten",
        "Norrbotten",
    ],
    other: [
        "Denmark",
        "Norway",
        "Finland",
        "Other EU/EES-country",
        "Other country outside EU/EES",
    ],
}

export const ageGroups = {
    '< 20': {
        from: 0,
        to: 19
    },
    '20 - 29': {
        from: 20,
        to: 29
    },
    '30 - 39': {
        from: 30,
        to: 39
    },
    '40 - 49': {
        from: 40,
        to: 49
    },
    '50 - 59': {
        from: 50,
        to: 59
    },
    '60 - 69': {
        from: 60,
        to: 69
    },
    '70 - 79': {
        from: 70,
        to: 79
    },
    '80 - 89': {
        from: 80,
        to: 89
    },
    '90 - 99': {
        from:90,
        to: 99
    },
    '> 99': {
        from: 99,
        to: 999
    },
}