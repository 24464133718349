import { useEffect, useState } from "react";
import { useGetAllCompanyInfo } from "./useGetAllCompanyInfo";
import { useGetAllEntryQuestionAnswers } from "./useGetAllEntryQuestionAnswers";
import { useGetAllIBOUsers } from "./useGetAllIBOUsers";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import { useGetBdOwners } from "../userData/useGetBdOwners";


export const useGetFullIBOData = () => {
    const compInfoList = useGetAllCompanyInfo();
    const entryQuestionList = useGetAllEntryQuestionAnswers();
    const iboUserList = useGetAllIBOUsers();
    const bdowners = useGetBdOwners();

    const [fullIBOList, setFullIBOList] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const dataSnap = await getDocs(query(collection(db, 'Projects')));
            const localIBOList = [];

            for (let index = 0; index < dataSnap.docs.length; index++) {
                const projDoc = dataSnap.docs[index];

                if (projDoc.data().isDev !== true) {
                    let projCompData = compInfoList[projDoc.id];
                    let entryQuestions = entryQuestionList[projDoc.id];
                    let IBOUserData = [];
                    let BDOwnerData;

                    if (projDoc.data().IBOOwner != null && Array.isArray(projDoc.data().IBOOwner)) {
                        for (let index = 0; index < projDoc.data().IBOOwner.length; index++) {
                            const userID = projDoc.data().IBOOwner[index];
                            let userData = iboUserList[userID];

                            if (userData != null) {
                                IBOUserData.push(userData);
                            }
                        }
                    }

                    if (projDoc.data().BDOwner != null && Array.isArray(projDoc.data().BDOwner)) {
                        for (let index = 0; index < projDoc.data().BDOwner.length; index++) {
                            const userID = projDoc.data().BDOwner[index];
                            let userData = bdowners.find((obj) => obj.id === userID);

                            if (userData != null) {
                                BDOwnerData = userData;
                            }
                        }
                    }

                    localIBOList.push({ id: projDoc.id, compData: projCompData, entryQuestions: entryQuestions, BDOwnerData: BDOwnerData, IBOUserData: IBOUserData, ...projDoc.data() })
                }

            }

            setFullIBOList(localIBOList);
        }

        if (Object.keys(compInfoList || {}).length > 0 && Object.keys(entryQuestionList || {}).length > 0 && Object.keys(iboUserList || {}).length > 0 && bdowners.length > 0) {
            getData();
        }
    }, [compInfoList, entryQuestionList, iboUserList, bdowners])


    return fullIBOList;
}