import { Autocomplete, Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Grid2, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { useAuth } from "../../contexts/AuthContext";
import { useEffect, useMemo, useState } from "react";
import { companyFormChoices, CompanyQuestionsEng, CompanyQuestionsSwe, ExtendedCompanyQuestionsEng, ExtendedCompanyQuestionsSwe } from "../../constants/companyQuestions/companyQuestions";
import dayjs from "dayjs";
import { useGetCompanyInfo } from "../../hooks/projectData/useGetCompanyInfo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BusinessIdeasQuestionsNewCompEng, BusinessIdeasQuestionsNewCompSwe } from "../../constants/ideasQuestions/businessIdeaQuestions";
import { useGetProjectEntryQuestions } from "../../hooks/projectData/useGetProjectEntryQuestions";
import { GLOBAL_GOALS_FIELDS } from "../../constants/lang/eng/fields";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { completeProjectInfoIncubator, confirmQuarterData, editNote, writeNote } from "../../firebase/cloudFunctions";
import { Timestamp } from "@firebase/firestore";
import { OwnershipCard } from "../ExtendedCompanyQuestions/CompanyOwnership/OwnershipCard";
import { CompanyStateAidCard } from "../ExtendedCompanyQuestions/CompanyStateAid/CompanyStateAidCard";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useGetExternalCommentData } from "../../hooks/commentData/useGetExternalCommentData";

export const CompleteProjectInfoIncubator = ({ projectData, update, setUpdate, quarterReviewPage = false }) => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const analytics = getAnalytics();
    const { companyInfo, isLoading: loadingCompInfo } = useGetCompanyInfo(projectData?.id);
    const { questionList, isLoading: loadingQuestions } = useGetProjectEntryQuestions(projectData?.id);
    const { comment, isLoading: loadingComment } = useGetExternalCommentData(projectData?.id, projectData?.data?.quarterCommentID);
    const [correctInfoChecked, setCorrectInfoChecked] = useState(false);

    const setInitYearFounded = () => {
        if (companyInfo?.founded != null && companyInfo?.founded !== "") {
            let localYear = new Date(Date.parse(companyInfo?.founded));
            return localYear;
        }
        else {
            return null;
        }
    }

    const CompanyQuestions = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return { ...CompanyQuestionsSwe, ...ExtendedCompanyQuestionsSwe };
        }
        else {
            return { ...CompanyQuestionsEng, ...ExtendedCompanyQuestionsEng };
        }
    }, [currentUser]);

    const BusinessQuestions = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return BusinessIdeasQuestionsNewCompSwe;
        }
        else {
            return BusinessIdeasQuestionsNewCompEng;
        }
    }, [currentUser]);

    const [compAnswers, setCompAnswers] = useState({ ...companyInfo })
    const [ideasAnswers, setIdeasAnswers] = useState(Object);

    const [editStatuseNote, setEditStatusNote] = useState(false);
    const [statusUpdate, setStatusUpdate] = useState('');

    const [isOwnershipInvalid, setOwnershipInvalid] = useState(false);
    const [isStateAidInvalid, setStateAidInvalid] = useState(false);
    const [statusUpdateInvalid, setStatusUpdateInvalid] = useState(true);

    useEffect(() => {
        if (loadingComment === false) {
            if (comment != null && comment?.message != null) {
                setStatusUpdate(comment.message);
                setEditStatusNote(true);
                setStatusUpdateInvalid(false);
            }
        }
    }, [loadingComment, comment])

    useEffect(() => {
        if (loadingCompInfo === false) {
            let localForms = { ...companyInfo };

            if (companyInfo?.founded != null && companyInfo?.founded !== "") {
                let localYear = new Date(Date.parse(companyInfo?.founded));
                setYear(localYear)
            }

            if (companyInfo?.compCity != null) {
                if (String(companyInfo?.compCity).includes(';') === true) {
                    let cityData = String(companyInfo?.compCity).split(';');

                    if (cityData[0] != null && cityData[0] !== "") {
                        localForms['county'] = { label: cityData[0] };
                    }
                    if (cityData[1] != null && cityData[1] !== "") {
                        localForms['region'] = { label: cityData[1] };
                    }
                }
            }

            setCompAnswers(localForms);
        }
    }, [companyInfo, loadingCompInfo]);

    useEffect(() => {
        let ideasKeys = Object.keys(BusinessQuestions);

        let localAnswers = {};
        for (let index = 0; index < ideasKeys.length; index++) {
            const ideaKey = ideasKeys[index];

            let ideaObject = questionList.find(obj => obj.id === ideaKey);
            if (ideaObject?.answerData?.answer != null && ideaObject?.answerData?.answer !== "") {
                localAnswers[ideaKey] = ideaObject?.answerData?.answer;
            }
            else {
                localAnswers[ideaKey] = "";
            }
        }

        setIdeasAnswers(localAnswers);
    }, [questionList, BusinessQuestions])

    const [year, setYear] = useState(setInitYearFounded());
    const [creatingProj, setCreatingProj] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [saveTrigger, setSaveTrigger] = useState('');
    const [autoSave, setAutoSave] = useState('');

    const [queueSubmit, setQueueSubmit] = useState(false);
    const [queueSave, setQueueSave] = useState(false);

    const [isGeneralInvalid, setGeneralInvalid] = useState(false);

    const [contactingKrinova, setContactKrinova] = useState('');
    const [existingCompany, setExistingCompany] = useState('');

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');



    const isValidUpdate = useMemo(() => {
        let changedFields = false;

        let companyKeys = Object.keys(CompanyQuestions);
        for (let index = 0; index < companyKeys.length; index++) {
            const key = companyKeys[index];

            if (CompanyQuestions[key].required === true) {
                if (compAnswers[key] != null && compAnswers[key] !== "" && compAnswers[key] !== companyInfo[key]) {
                    changedFields = true;
                }
                else if (compAnswers[key] == null || compAnswers[key] === "") {
                    return false;
                }
            }
            else {
                if (compAnswers[key] !== companyInfo[key]) {
                    changedFields = true;
                }
            }
        }

        let ideasKeys = Object.keys(BusinessQuestions);

        for (let index = 0; index < ideasKeys.length; index++) {
            const ideaKey = ideasKeys[index];

            let ideaObject = questionList.find(obj => obj.id === ideaKey);
            if (BusinessQuestions[ideaKey]?.required === true) {
                //console.log("Data: ", ideaKey, ideaObject?.answerData, answers[ideaKey])
                if (ideaObject != null && ideaObject?.answerData != null && ideaObject?.answerData !== "" && ideaObject?.answerData?.answer != null && ideaObject?.answerData?.answer !== "") {
                    if (ideaObject?.answerData?.answer !== ideasAnswers[ideaKey] && ideasAnswers[ideaKey] != null && ideasAnswers[ideaKey] !== "") {
                        changedFields = true;
                    }
                    else if (ideasAnswers[ideaKey] == null || ideasAnswers[ideaKey] === "") {
                        return false;
                    }
                }
                else if (ideasAnswers[ideaKey] == null || ideasAnswers[ideaKey] === "") {
                    if (BusinessQuestions[ideaKey]?.requiresKey != null && ideasAnswers[BusinessQuestions[ideaKey]?.requiresKey] === BusinessQuestions[ideaKey]?.requiresVal) {
                        return false;
                    }
                }
                else {
                    changedFields = true;
                }
            }
            else {
                if (ideaObject?.answerData?.answer !== ideasAnswers[ideaKey]) {
                    changedFields = true;
                }
            }
        }

        return changedFields;
    }, [companyInfo, compAnswers, CompanyQuestions, ideasAnswers, questionList, BusinessQuestions, update]);

    const handleChangeCompInfo = (e) => {
        const { name, value } = e.target;
        const localForms = compAnswers;

        if (name === "region") {
            //console.log("Region: ", value?.label)
            if (localForms["county"] != null && localForms["county"] !== "") {
                localForms["county"] = "";
            }
            localForms['compCity'] = ";" + value?.label;
        }
        else if (name === "county") {
            //console.log("County: ", value?.label)
            if (localForms["region"] != null && localForms["region"] !== "") {
                localForms['compCity'] = value?.label + ";" + localForms["region"].label;
            }
            else {
                localForms['compCity'] = value?.label + ";";
            }
        }
        localForms[name] = value;

        setCompAnswers(localForms);
        setUpdate(!update);
    };

    const handleChangeIdeaQuestions = (event) => {
        let localAnswers = { ...ideasAnswers };
        const value = event.target.value;
        localAnswers[event.target.name] = value;
        setIdeasAnswers(localAnswers);
        setUpdate(!update);
    }

    const handleRadioValue = (key) => {
        if (key === CompanyQuestions.compForm.key) {
            if (compAnswers[key] === companyFormChoices.Aktiebolag.label) {
                handleChangeCompInfo({ target: { name: "compForm", value: companyFormChoices.Aktiebolag.value } });
                return companyFormChoices.Aktiebolag.value;
            }
            else if (compAnswers[key] === companyFormChoices["Enskild firma"].label) {
                handleChangeCompInfo({ target: { name: "compForm", value: companyFormChoices["Enskild firma"].value } });
                return companyFormChoices["Enskild firma"].value;
            }
            else if (compAnswers[key] === companyFormChoices["Handelsbolag/Kommanditbolag"].label) {
                handleChangeCompInfo({ target: { name: "compForm", value: companyFormChoices["Handelsbolag/Kommanditbolag"].value } });
                return companyFormChoices["Handelsbolag/Kommanditbolag"].value;
            }
            else if (compAnswers[key] != null) {
                return compAnswers[key]
            }
            else {
                return null;
            }
        }
        else if (compAnswers[key] != null) {
            return compAnswers[key]
        }
        else {
            return null;
        }
    }

    const handleChangeLocalCompInfo = (e) => {
        handleChangeCompInfo(e);
        if (e.target.name === 'compName') {
            handleChangeCompInfo({ target: { name: "projName", value: e.target.value } });
        }
        setSaveTrigger(e.target.value);
        setUpdate(!update);
    };

    const handleChangeRegion = (value) => {
        //console.log(value);
        handleChangeCompInfo({ target: { name: "region", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };

    const handleChangeCounty = (value) => {
        //console.log(value);
        handleChangeCompInfo({ target: { name: "county", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };

    const handleChangeCountry = (value) => {
        //console.log(value);
        handleChangeCompInfo({ target: { name: "country", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };

    const handleChangeOwneship = (value) => {
        handleChangeCompInfo({ target: { name: "compOwnership", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    }

    const handleChangeStateAidValue = (value) => {
        handleChangeCompInfo({ target: { name: "compStateAidValue", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    }

    const onChangeYear = (value) => {
        setYear(value['$d']);
        handleChangeCompInfo({ target: { name: "founded", value: value['$d'].getFullYear() } });
    }

    function collectIdeaData() {
        const dataToSend = [];

        let ideasKeys = Object.keys(BusinessQuestions);
        for (let index = 0; index < ideasKeys.length; index++) {
            const ideaKey = ideasKeys[index];

            if (ideasAnswers[ideaKey] != null && ideasAnswers[ideaKey] !== "") {
                if (ideaKey === 'globalGoals') {
                    let goalsString = "";
                    Object.keys(ideasAnswers[ideaKey]).forEach((goal) => {
                        if (ideasAnswers[ideaKey][goal] === true) {
                            goalsString += GLOBAL_GOALS_FIELDS[goal] + ";"
                        }
                    });

                    dataToSend.push({ title: ideaKey, answer: goalsString })
                }
                else {
                    dataToSend.push({ title: ideaKey, answer: ideasAnswers[ideaKey] })
                }

            }
        }

        return dataToSend;
    }

    function collectCompData() {
        const dataToSend = {};
        dataToSend['existingComp'] = "yes_established";
        let companyKeys = Object.keys(CompanyQuestions);
        for (let index = 0; index < companyKeys.length; index++) {
            const key = companyKeys[index];

            if (compAnswers[key] != null && compAnswers[key] !== "") {
                dataToSend[key] = compAnswers[key];
            }
        }

        if (compAnswers['compCity'] != null && compAnswers['compCity'] !== "" ){
            dataToSend['compCity'] = compAnswers['compCity'];
        }

        return dataToSend;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (quarterReviewPage === true) {
            handleConfirmQuarterReview();
        }
        else {
            handleCompleteProjectInfoIncubator();
        }
    }

    const handleCompleteProjectInfoIncubator = () => {
        setIsLoading(true);

        const ideaData = collectIdeaData();
        const compData = collectCompData();

        const dataSnap = {
            projectID: projectData.id,
            entryQuestions: ideaData,
            ...compData,
        }

        completeProjectInfoIncubator(dataSnap).then(() => {
            //navigate("/home", { replace: true });
        }).catch((error) => {
            setErrorInfo(error);
            setErrorMsg('Something went wrong! Could not save data.');
            setError(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleConfirmQuarterReview = () => {
        setIsLoading(true);

        const ideaData = collectIdeaData();
        const compData = collectCompData();

        if (editStatuseNote === true && comment?.id != null) {
            editNote({
                messageID: comment.id,
                projectID: projectData.id,
                isExternal: true,
                message: statusUpdate,
            }).then(() => {
                let datasnap = {
                    projectID: projectData.id,
                    ideaData: ideaData,
                    compData: compData,
                }

                confirmQuarterData(datasnap).then((resConfirm) => {
                    logEvent(analytics, 'completed_confirm_quarter_review', {
                        projectID: projectData.id,
                    });

                    if (resConfirm.data === true) {
                        navigate("/home", { replace: true });
                    }
                }).catch((error) => {
                    console.error("Error", error)
                });
            }).catch((error) => {
                console.error("Error", error)
            }).finally(() => {
                setIsLoading(false);
            });
        }
        else {
            writeNote({
                projectID: projectData.id,
                isExternal: true,
                location: 'Quarter Review',
                message: statusUpdate,
            }).then((resNote) => {
                console.log("Note Res: ", resNote)
                let datasnap = {
                    projectID: projectData.id,
                    ideaData: ideaData,
                    compData: compData,
                }

                if (resNote?.data != null) {
                    datasnap['commentID'] = resNote.data;
                }
                confirmQuarterData(datasnap).then((resConfirm) => {
                    logEvent(analytics, 'completed_confirm_quarter_review', {
                        projectID: projectData.id,
                    });

                    if (resConfirm.data === true) {
                        navigate("/home", { replace: true });
                    }
                }).catch((error) => {
                    console.error("Error", error)
                });
            }).catch((error) => {
                console.error("Error", error)
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const handleUpdateStatus = (event) => {
        setStatusUpdate(event.target.value);
        setStatusUpdateInvalid(false);
    }

    const validateUpdate = () => {
        if (quarterReviewPage === true) {
            if (statusUpdate != null && statusUpdate !== "") {
                setStatusUpdateInvalid(false);
            }
            else {
                setStatusUpdateInvalid(true);
            }
        }
        else {
            setStatusUpdateInvalid(false);
        }
    }

    /**
     * 
     * @param {Timestamp} fbTimestamp 
     */
    function firestoreTimestampToDate(fbTimestamp) {
        return fbTimestamp.toDate().toLocaleString('se-SV');
    }

    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Divider>{currentUser?.userInfo?.prefLang === 'swe' ? ("Företagsinfo") : ("Company info")}</Divider>
                <Grid2 container spacing={3} justifyContent="center" padding={1}>
                    {Object.keys(CompanyQuestions).map((key) => {
                        if (CompanyQuestions[key]?.type === 'textField') {
                            return (
                                <Grid2 key={key} size={{ xs: 12, sm: 12, md: 6 }} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <TextField
                                        fullWidth
                                        error={CompanyQuestions[key]?.required === true && (compAnswers[key] == null || compAnswers[key] === "")}
                                        required={CompanyQuestions[key]?.required === true}
                                        label={CompanyQuestions[key]?.text}
                                        name={key}
                                        type={'text'}
                                        onChange={handleChangeLocalCompInfo}
                                        value={compAnswers[key] || ""}
                                        placeholder={CompanyQuestions[key]?.placeHolder != null ? CompanyQuestions[key]?.placeHolder : ""}
                                        margin="normal"
                                    />
                                </Grid2>
                            )
                        }
                        else if (CompanyQuestions[key]?.type === 'radioGroup') {
                            return (
                                <Grid2 key={key} size={{ xs: 12, sm: 12, md: 6 }} sx={{ display: 'flex', justifyContent: 'flex-start' }}>

                                    <FormControl error={CompanyQuestions[key]?.required === true && (compAnswers[key] == null || compAnswers[key] === "")} required={CompanyQuestions[key]?.required}>
                                        <FormLabel id={key}>{CompanyQuestions[key]?.text}</FormLabel>
                                        <RadioGroup
                                            sx={{ width: 'fit-content' }}
                                            aria-labelledby={key}
                                            name={key}
                                            value={handleRadioValue(key)}
                                            onChange={handleChangeLocalCompInfo}
                                        >
                                            {CompanyQuestions[key]?.options.map((option) => (
                                                <FormControlLabel
                                                    key={option?.value}
                                                    value={option?.value}
                                                    control={<Radio />}
                                                    label={option?.label}
                                                />
                                            ))}
                                        </RadioGroup>

                                    </FormControl>
                                </Grid2>
                            )
                        }
                        else if (CompanyQuestions[key]?.type === 'autoCompleteRegion') {
                            return (
                                <Grid2 key={key} size={{ xs: 12, sm: 12, md: 6 }} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Autocomplete
                                        disablePortal
                                        autoComplete
                                        value={compAnswers[key] || null}
                                        options={CompanyQuestions[key]?.options}
                                        onChange={(event, newValue) => {
                                            handleChangeRegion(newValue);
                                        }}
                                        fullWidth
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label={CompanyQuestions[key]?.text}
                                                error={CompanyQuestions[key]?.required === true && (compAnswers[key] == null || compAnswers[key] === "")}
                                                margin="normal"
                                                required={CompanyQuestions[key]?.required}
                                            />
                                        }
                                    />

                                    {compAnswers.region?.label === "Skåne" ? (
                                        <Autocomplete
                                            disablePortal
                                            autoComplete
                                            value={compAnswers[CompanyQuestions[key]?.keyScania]}
                                            options={CompanyQuestions[key]?.optionsScania}
                                            onChange={(event, newValue) => {
                                                handleChangeCounty(newValue);
                                            }}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} label={CompanyQuestions[key]?.textScania} required={CompanyQuestions[key]?.required} margin="normal" />}

                                        />) : compAnswers.region?.label === "Outside Sweden" ? (
                                            <Autocomplete
                                                disablePortal
                                                autoComplete
                                                value={compAnswers[CompanyQuestions[key]?.keyOutsideSweden]}
                                                options={CompanyQuestions[key]?.optionsOutsideSweden}
                                                onChange={(event, newValue) => {
                                                    handleChangeCountry(newValue);
                                                }}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} label={CompanyQuestions[key]?.textOutsideSweden} required={CompanyQuestions[key]?.required} margin="normal" />}
                                            />
                                        ) : ("")}
                                </Grid2>
                            )
                        }
                        else if (CompanyQuestions[key]?.type === 'datePickerYear') {
                            return (
                                <Grid2 key={key} size={{ xs: 12, sm: 12, md: 6 }} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <DesktopDatePicker

                                        views={['year']}
                                        label={CompanyQuestions[key]?.text}
                                        format="YYYY"
                                        value={year ? dayjs(year) : null}
                                        disableFuture
                                        onChange={onChangeYear}
                                        // @ts-ignore
                                        slotProps={{ textField: { helperText: null, margin: 'normal', required: CompanyQuestions[key]?.required, fullWidth: true, error: CompanyQuestions[key]?.required === true && (year == null || year === "") } }}
                                    />
                                </Grid2>
                            )
                        }
                        else if (CompanyQuestions[key]?.type === 'compOwnershipCard') {
                            return (
                                <Grid2 key={key} size={{ xs: 12, sm: 12, md: 6 }} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <OwnershipCard
                                        value={compAnswers[key] || null}
                                        handleChangeOwneship={handleChangeOwneship}
                                        isOwnershipInvalid={isOwnershipInvalid}
                                        setOwnershipInvalid={setOwnershipInvalid}
                                    />
                                </Grid2>
                            )
                        }
                        else if (CompanyQuestions[key]?.type === 'compStateAidCard' && compAnswers[CompanyQuestions[key]?.requiredKey] === CompanyQuestions[key]?.requiredKeyValue) {
                            return (
                                <Grid2 key={key} size={{ xs: 12, sm: 12, md: 6 }} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <CompanyStateAidCard
                                        value={compAnswers[key] || null}
                                        handleChangeStateAid={handleChangeStateAidValue}
                                        isStateAidInvalid={isStateAidInvalid}
                                        setStateAidInvalid={setStateAidInvalid}
                                    />
                                </Grid2>
                            )
                        }
                    })}
                </Grid2>

                <Divider>{currentUser?.userInfo?.prefLang === 'swe' ? ("Business idea") : ("Business idea")}</Divider>
                <Grid2 container spacing={3} justifyContent="center" padding={1}>
                    {Object.keys(BusinessQuestions).map((ideaKey, index) => {
                        const textlabel = BusinessQuestions[ideaKey]?.orderLabel + " " + BusinessQuestions[ideaKey]?.text;
                        if ((BusinessQuestions[ideaKey]?.requiresKey != null && ideasAnswers[BusinessQuestions[ideaKey]?.requiresKey] === BusinessQuestions[ideaKey]?.requiresVal) || BusinessQuestions[ideaKey]?.requiresKey == null) {
                            if (BusinessQuestions[ideaKey].type === 'multiCheckbox') {
                                const options = BusinessQuestions[ideaKey]?.options;
                                return (
                                    <Grid2 key={ideaKey} size={{ xs: 12, sm: 12, md: 6 }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                                        <FormControl sx={{ width: '97%', gap: '4px' }} required={BusinessQuestions[ideaKey]?.required} component="fieldset" error={(ideasAnswers[ideaKey] == null || ideasAnswers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required}>
                                            {index === 0 ? (<h6 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Business idea:</h6>) : ("")}
                                            <FormLabel sx={{ marginX: '5px' }}>{textlabel}</FormLabel>
                                            <FormGroup aria-label="position" id="reference">
                                                {options.map((option) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={option?.key}
                                                            control={<Checkbox
                                                                value={option?.value || null}
                                                                checked={ideasAnswers[ideaKey] != null && ideasAnswers[ideaKey][option?.key] != null ? ideasAnswers[ideaKey][option?.key] : false}
                                                                onChange={handleChangeIdeaQuestions}
                                                                name={ideaKey}
                                                                color="primary"
                                                            />}
                                                            label={option?.label}
                                                        />
                                                    )
                                                })}
                                            </FormGroup>
                                            {(ideasAnswers[ideaKey] == null || ideasAnswers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required ? (
                                                <FormHelperText>{(ideasAnswers[ideaKey] == null || ideasAnswers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required ? ("Answer is missing") : ("")}</FormHelperText>
                                            ) : (
                                                <Box height={'24px'}>

                                                </Box>
                                            )}
                                        </FormControl>
                                    </Grid2>
                                )
                            }
                            else if (BusinessQuestions[ideaKey].type === 'radioGroup') {
                                const options = BusinessQuestions[ideaKey]?.options;
                                return (
                                    <Grid2 key={ideaKey} size={{ xs: 12, sm: 12, md: 6 }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                                        <FormControl sx={{ width: '97%', gap: '4px' }} required={BusinessQuestions[ideaKey]?.required} error={(ideasAnswers[ideaKey] == null || ideasAnswers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required}>
                                            <FormLabel sx={{ marginX: '5px' }} id="demo-controlled-radio-buttons-group">{textlabel}</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name={ideaKey}
                                                sx={{ height: '120px', width: 'fit-content' }}
                                                value={ideasAnswers[ideaKey] || null}
                                                onChange={handleChangeIdeaQuestions}
                                            >
                                                {options.map((option) => (
                                                    <FormControlLabel
                                                        key={option?.value}
                                                        value={option?.value}
                                                        control={<Radio />}
                                                        label={option?.label}
                                                    />
                                                ))}
                                            </RadioGroup>
                                            {(ideasAnswers[ideaKey] == null || ideasAnswers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required ? (
                                                <FormHelperText>{(ideasAnswers[ideaKey] == null || ideasAnswers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required ? ("Answer is missing") : ("")}</FormHelperText>
                                            ) : (
                                                <Box height={'24px'}>

                                                </Box>
                                            )}
                                        </FormControl>
                                    </Grid2>
                                );
                            }
                            else {
                                return (
                                    <Grid2 key={ideaKey} size={{ xs: 12, sm: 12, md: 6 }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                                        <FormControl sx={{ width: '97%', gap: '4px' }} component="fieldset" required={BusinessQuestions[ideaKey]?.required} error={(ideasAnswers[ideaKey] == null || ideasAnswers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required}>
                                            <FormLabel sx={{ marginX: '5px' }}>{textlabel}</FormLabel>
                                            <TextField
                                                label="Answer"
                                                multiline
                                                name={ideaKey}
                                                rows={4}
                                                onChange={handleChangeIdeaQuestions}
                                                value={ideasAnswers[ideaKey] || ""}
                                            />
                                            {(ideasAnswers[ideaKey] == null || ideasAnswers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required ? (
                                                <FormHelperText>{(ideasAnswers[ideaKey] == null || ideasAnswers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required ? ("Answer is missing") : ("")}</FormHelperText>
                                            ) : (
                                                <Box height={'24px'}>

                                                </Box>
                                            )}

                                        </FormControl>
                                    </Grid2>
                                );
                            }
                        }
                    })}

                    {quarterReviewPage === true ? (
                        <>
                            <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                                <FormControl sx={{ width: '100%' }} required>
                                    <FormLabel sx={{ height: '55px' }}>Write a brief summary of the main activities or events relevant to the development of your idea or business since your last update.</FormLabel>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        name={'statusUpdate'}
                                        rows={4}
                                        onChange={handleUpdateStatus}
                                        value={statusUpdate || ""}
                                        onBlur={() => { validateUpdate() }}
                                        error={statusUpdateInvalid}
                                    />
                                </FormControl>
                            </Grid2>
                        </>
                    ) : ("")}
                </Grid2>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: quarterReviewPage === true ? '10px' : "-20px", marginBottom: '30px' }}>
                    <FormControlLabel required control={<Checkbox checked={correctInfoChecked} onChange={() => setCorrectInfoChecked(!correctInfoChecked)} />} label={currentUser?.userInfo?.prefLang === 'swe' ? ("Jag intygar att alla uppgifter är korrekta och aktuella") : ("I verify that all information is correct and up-to-date")} />
                    {quarterReviewPage === true ? (
                        <>
                            {projectData?.data?.lastAnsweredQuarterData != null ? (
                                <Typography color={'#28a745'} fontStyle={'italic'} marginBottom={'5px'}>
                                    {currentUser?.userInfo?.prefLang === 'swe' ? (
                                        "Skickades in för granskning den " + firestoreTimestampToDate(projectData?.data?.lastAnsweredQuarterData)
                                    ) : (
                                        "Has been submitted for review at: " + firestoreTimestampToDate(projectData?.data?.lastAnsweredQuarterData)
                                    )}
                                </Typography>
                            ) : ("")}

                            <LoadingButton
                                sx={{ width: '170px' }}
                                onClick={handleSubmit}
                                loading={isLoading}
                                loadingPosition="start"
                                variant="contained"
                                disabled={!isValidUpdate || correctInfoChecked === false || isOwnershipInvalid === true || statusUpdateInvalid == true || (compAnswers[CompanyQuestions.compStateAid.key] === 'yes' && isStateAidInvalid === true)}
                            >
                                {currentUser?.userInfo?.prefLang === 'swe' ? ("Lämna in") : ("Submit")}
                            </LoadingButton>
                        </>
                    ) : (
                        <>
                            {projectData?.data?.submittedData != null ? (
                                <Typography color={'#28a745'} fontStyle={'italic'} marginBottom={'5px'}>
                                    {currentUser?.userInfo?.prefLang === 'swe' ? (
                                        "Skickades in för granskning den " + firestoreTimestampToDate(projectData?.data?.submittedData)
                                    ) : (
                                        "Has been submitted for review at: " + firestoreTimestampToDate(projectData?.data?.submittedData)
                                    )}
                                </Typography>
                            ) : ("")}

                            <LoadingButton
                                sx={{ width: '170px' }}
                                onClick={handleSubmit}
                                loading={isLoading}
                                loadingPosition="start"
                                variant="contained"
                                disabled={!isValidUpdate || correctInfoChecked === false || isOwnershipInvalid === true || (compAnswers[CompanyQuestions.compStateAid.key] === 'yes' && isStateAidInvalid === true)}
                            >
                                {currentUser?.userInfo?.prefLang === 'swe' ? ("Lämna in") : ("Submit")}
                            </LoadingButton>
                        </>
                    )}
                </div>
            </LocalizationProvider>
        </Box>
    )
}