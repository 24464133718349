import { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
//import TableIbo from './Table'
import '../../assets/scss/app.scss'
import useGetUserData from '../../hooks/userData/useGetUserData'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Card, CircularProgress, TextField, Button, Divider, Select, MenuItem, Stack, Grid, Grid2 } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import sweFlag from '../../assets/images/swe_flag.png';
import uk_us_Flag from '../../assets/images/us_uk_flag.png'
import { updateLanguage } from '../../firebase/cloudFunctions';
import { ChangePasswordCard } from '../UserSettings/ChangePasswordCard';
import { PreferredTableSettingsCard } from '../UserSettings/PreferredTableSettings';

const AccountSettings = () => {
    const { currentUser, changePassword, deactivateAccont } = useAuth();
    const { userData, loading } = useGetUserData();
    const [passLoading, setPassLoading] = useState(false);
    const [accountLoading, setAccountLoading] = useState(false);
    const [oldPass, setOldPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [newAgainPass, setNewAgainPass] = useState('');
    const [errorText, setErrorText] = useState('');
    const [errorPassAgain, setErrorPassAgain] = useState(false);
    const [errorPass, setErrorPass] = useState(false);
    const [errorOldPass, setErrorOldPass] = useState(false);

    const [errorResponse, setErrorResponse] = useState('');
    const [saved, setSaved] = useState(false);
    const [language, setLanguage] = useState('eng');
    const [savingLanguage, setSavingLanguage] = useState(false);

    const [openDeactivate, setOpenDeactivate] = useState(false);
    const [deactivationPass, setDeactivationPass] = useState('');
    const [errorResponseDeactive, setErrorResponseDeactive] = useState('');

    useEffect(() => {
        if (loading === false && userData?.prefLang != null) {
            setLanguage(userData?.prefLang);
        }
    }, [userData, loading, currentUser])

    const handleClickOpenDeactivation = () => {
        setOpenDeactivate(true);
    };

    const handleCloseDeactivation = () => {
        setOpenDeactivate(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setPassLoading(true)

        let isOk = true;
        if (oldPass === '') {
            isOk = false;
            setErrorOldPass(true);
        }
        else {
            setErrorOldPass(false);
        }

        if (newPass === '') {
            isOk = false;
            setErrorPass(true);
        }
        else {
            setErrorPass(false);
        }

        if (newAgainPass === '') {
            isOk = false;
            setErrorPassAgain(true);
        }
        else {
            setErrorPassAgain(false);
        }

        if (isOk) {
            const response = await changePassword(oldPass, newPass);
            if (response != null) {
                setErrorResponse(response.message);
                setSaved(false);
            }
            else {
                setErrorResponse('');
                setSaved(true);
            }
        }

        setPassLoading(false)
    }


    const setOldPassChange = (event) => {
        setOldPass(event.target.value)

        if (event.target.value === '') {
            setErrorOldPass(true);
        }
        else {
            setErrorOldPass(false);
        }
    }

    const setNewPassChange = (event) => {
        setNewPass(event.target.value)

        if (event.target.value === '') {
            setErrorPass(true);
        }
        else {
            setErrorPass(false);
        }
    }

    const setNewPassAgainChange = (event) => {
        setNewAgainPass(event.target.value)

        if (event.target.value !== newPass) {
            setErrorPassAgain(true);
            setErrorText("Passwords does not match!")

        }
        else {
            setErrorPassAgain(false);
            setErrorText("");
        }
    }

    const handleDeleteUser = async () => {
        setAccountLoading(true);
        console.log("He gone...");
        const response = await deactivateAccont(deactivationPass);
        if (response != null) {
            setErrorResponseDeactive(response.message);
        }
        else {
            setErrorResponseDeactive('');
        }
        handleCloseDeactivation()
        setAccountLoading(false);
    }

    const updateFBLanguage = (value) => {
        setSavingLanguage(true);
        updateLanguage({
            userID: currentUser.uid,
            prefLang: value,
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setSavingLanguage(false);
        })
    }


    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
        updateFBLanguage(event.target.value);
    }

    return (
        <>
            {!loading ? (
                <>
                    <span><strong>Account Settings</strong></span>
                    {/*
                    <Box sx={{ minWidth: 275, maxWidth: "80%", paddingTop: 8, paddingBottom: 3 }}>
                        <Card>
                            <h6 style={{ paddingLeft: 10, paddingTop: 15 }}>Language:</h6>
                            <Box style={{ paddingLeft: 18, paddingTop: 5, width: "70%" }}>
                                <Stack direction={'row'} alignItems="center" spacing={2}>
                                    <Select
                                        sx={{ width: '200px' }}
                                        value={language}
                                        onChange={handleLanguageChange}
                                        size='small'
                                        disabled={savingLanguage}
                                    >
                                        <MenuItem key={'eng'} value={'eng'}>
                                            <Stack direction={'row'} alignItems="center" spacing={1}>
                                                <img
                                                    alt="eng"
                                                    src={uk_us_Flag}
                                                    width="25px"
                                                    height="16px"
                                                />
                                                <span>English</span>
                                            </Stack>
                                        </MenuItem>
                                        <MenuItem key={'swe'} value={'swe'}>
                                            <Stack direction={'row'} alignItems="center" spacing={1}>
                                                <img
                                                    alt="swe"
                                                    src={sweFlag}
                                                    width="25px"
                                                    height="16px"
                                                />
                                                <span>Svenska</span>
                                            </Stack>
                                        </MenuItem>
                                    </Select>

                                    {savingLanguage === true ? (
                                        <CircularProgress size={25} />
                                    ) : ("")}
                                    
                                </Stack>

                            </Box>
                        </Card>
                    </Box>
                    <Divider />
                    */}

                    <Grid2 container spacing={2} sx={{ paddingTop: 8, paddingBottom: 3 }}>
                        <Grid2>
                            <ChangePasswordCard />
                        </Grid2>


                        {currentUser.role >= 2 ? (
                            <Grid2>
                                <PreferredTableSettingsCard />
                            </Grid2>
                        ) : ("")}

                    </Grid2>
                    <Divider />
                    <Box sx={{ minWidth: 275, maxWidth: "80%", paddingTop: 3, }}>
                        <Card >
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '45ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div className="form-group" style={{ paddingLeft: 10 }}>
                                    <LoadingButton
                                        id="deactivate-btn"

                                        loading={accountLoading}
                                        variant="contained"
                                        onClick={handleClickOpenDeactivation}
                                    >
                                        Deactivate Account
                                    </LoadingButton>
                                    {errorResponseDeactive !== '' ? (
                                        <div className="form-group" style={{ paddingLeft: 5, paddingTop: 10, color: 'red' }}>
                                            Something went wrong! {errorResponseDeactive}
                                        </div>
                                    ) : (<></>)}

                                    <Dialog open={openDeactivate} onClose={handleCloseDeactivation}>
                                        <DialogTitle>Deactivate account</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText sx={{ paddingBottom: 2 }}>
                                                Are you sure you want to deactivate your account? This cannot be reverted!
                                            </DialogContentText>
                                            <DialogContentText>
                                                In order to confirm your deactivation, please enter your password:
                                            </DialogContentText>
                                            <div>
                                                <TextField
                                                    autoFocus
                                                    id="name"
                                                    label="Password"
                                                    type="password"
                                                    onChange={(event) => { setDeactivationPass(event.target.value) }}
                                                    fullWidth
                                                    variant="standard"
                                                />
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseDeactivation}>Cancel</Button>
                                            <Button color="error" disabled={deactivationPass === ''} onClick={handleDeleteUser}>Deactivate</Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            </Box>
                        </Card>
                    </Box>
                </>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            )
            }


        </>
    )
}

export default AccountSettings
