import { useMemo } from "react";
import { CircularProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip } from 'recharts';
import { capitalizeWord } from "../../../../../helper/stringHelpers";
import { CompanyChoices, CompanyChoicesShortLabels } from "../../../../../constants/labels/labels";

export const RegCompGraph = ({ isLoading, regCompData }) => {
    const chartData = useMemo(() => {
        let data = []

        Object.keys(CompanyChoices).forEach((key) => {
            data.push({ name: CompanyChoices[key], shortName: CompanyChoicesShortLabels[key], count: regCompData[key] });
        });

        return data;
    }, [regCompData]);

    const CustomTooltip = ({ active = null, payload = null, label = null }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{backgroundColor: 'white', paddingTop: '13px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0.1px', border: '1px solid #d0d0d0'}}>
                    <p>{`${payload[0].payload?.name}`}</p>
                    <p style={{color: '#007bff'}}>{`${payload[0].name} : ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            ) : (
                <Stack spacing={3}>
                    <BarChart
                        width={500}
                        height={300}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="shortName" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Bar dataKey="count" fill="#007bff" />
                    </BarChart>

                    <Table
                        sx={{ justifyContent: 'center' }}
                        aria-labelledby="statsTable"
                        aria-label="Stats Table">
                        <TableHead>
                            <TableRow>
                                {Object.keys(CompanyChoices).map((option) => {
                                    return (
                                        <TableCell key={option + "-header"}>
                                            {CompanyChoices[option]}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {Object.keys(CompanyChoices).map((option, index) => {
                                    return (
                                        <TableCell key={option + "-" + index}>
                                            {regCompData[option]}
                                        </TableCell>);
                                })}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            )}
        </>
    )
}