import { useState } from 'react';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { Alert, Checkbox, FormControlLabel, FormHelperText, Grid2, LinearProgress, Tooltip } from '@mui/material';
import { useNavigate } from "react-router-dom"
import { bookTimeSlot, setAlreadyBookedFM } from '../../../firebase/cloudFunctions';
import { useGetAllAvailableTimes } from '../../../hooks/timeslotData/useGetAllAvailableTimes';
import { useGetUserProjectId } from '../../../hooks/userData/useGetUserProjectId';
import { ErrorAlert } from '../../../helper/errorAlert';
import { getAnalytics, logEvent } from 'firebase/analytics';

import textBubble from '../../../assets/images/tip-bubble.png'
import { useAuth } from '../../../contexts/AuthContext';
import useGetUserProject from '../../../hooks/userData/useGetUserProject';
import { useGetPublicBdOwners } from '../../../hooks/userData/useGetBdOwners';
import { useGetSignUpTexts } from '../../../hooks/languageHooks/useGetSignUpTexts';

function FirstMeetDatePicker() {
    const { currentUser } = useAuth();
    const bookMeetingLabels = useGetSignUpTexts();
    const bdOwners = useGetPublicBdOwners(false)
    const [alltimes] = useGetAllAvailableTimes();
    //const { projectIDs } = useGetUserProjectId();
    const { project } = useGetUserProject()
    const [timeSlot, setTimeSlot] = useState('');
    const [selectedBD, setSelectedBD] = useState('');
    const [alreadyBooked, setAlreadyBooked] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const navigate = useNavigate()
    const analytics = getAnalytics();

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    //define `openSnackbar` state and the function to change it. The value is false by default
    const [openSnackbar, setOpenSnackbar] = useState(false);

    //define `isLoading` state and the function to change it. The value is false by default
    const [isLoading, setIsLoading] = useState(false);


    const handleChange = (event) => {
        setTimeSlot(event.target.value);
        setDisplayError(false);
    };

    const handleChangeSelectedBD = (event) => {
        setSelectedBD(event.target.value);
        setDisplayError(false);
    };

    const handleSetChecked = () => {
        if (alreadyBooked === false) {
            setTimeSlot('');
        }

        setAlreadyBooked(!alreadyBooked);
    }

    //this function is called when the user closes the snackbar after getting an error (when the cloud function fails)
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };


    const submitTimeSlot = async () => {
        if (timeSlot != null && timeSlot !== '') {
            setIsLoading(true);
            bookTimeSlot({
                timeSlotID: timeSlot,
                projectID: project.id,
                step: "meet1"
            }).then((data) => {
                logEvent(analytics, 'completed_signup_step', {
                    step: 4,
                    invited: false,
                    alreadyBooked: alreadyBooked,
                })
                navigate("/home")
            }).catch((error) => {
                setErrorInfo(error)
                setErrorMsg('Something went wrong! Could not book timeslot!');
                setError(true)
            }).finally(() => {
                setIsLoading(false);
            })
        }
        else {
            setDisplayError(true)
        }
    };


    const handleCompleteStep = () => {
        if (alreadyBooked === true && selectedBD != null && selectedBD !== "") {
            setIsLoading(true);
            setAlreadyBookedFM({
                userID: currentUser.uid,
                projectID: project.id,
                alreadyBooked: alreadyBooked,
                selectedBD: selectedBD,
            }).then(() => {
                logEvent(analytics, 'completed_signup_step', {
                    step: 4,
                    invited: false,
                    alreadyBooked: alreadyBooked,
                })
                navigate("/home")
            }).catch((error) => {
                setErrorInfo(error)
                setErrorMsg('Something went wrong! Could not complete signup!');
                setError(true)
            }).finally(() => {
                setIsLoading(false);
            });
        }
        else if (timeSlot != null && timeSlot !== "") {
            submitTimeSlot();
        }
    }

    //console.log("ProjData: ", project.data, bdOwners, currentUser)

    return (
        <Box mt="3rem" mb="6rem" >
            <Grid2 container alignItems="center" direction="column">
                <span className="book-a-meeting-text" style={{ marginBottom: '20px', marginTop: '20px' }}>{bookMeetingLabels.bookMeetingTitle}</span>
                <Box className="book-a-meeting-box">
                    <FormControl fullWidth>
                        <InputLabel id="combo-box-timeslot">{bookMeetingLabels.availTimes}</InputLabel>
                        <Select
                            id="combo-box-timeslot"
                            label={bookMeetingLabels.availTimes}
                            value={timeSlot}
                            onChange={handleChange}
                            disabled={alreadyBooked}
                        >
                            <MenuItem sx={{ height: '28px' }} value={''}></MenuItem>
                            {alltimes.map((allavail) => {
                                return (
                                    <MenuItem
                                        key={allavail.id}
                                        value={allavail.id}>{allavail.startTime}-{allavail.endTime} </MenuItem>
                                )
                            })}
                        </Select>

                    </FormControl>
                </Box>
                <Box>
                    <Box className="book-a-meeting-checkbox" mt="1rem">
                        <Tooltip arrow placement={'top'} title={project?.data?.alreadyBookedFM === false ? bookMeetingLabels.alreadyBookedFMDenied : ""}>
                            <FormControlLabel control={<Checkbox checked={alreadyBooked} onChange={handleSetChecked} />} label={bookMeetingLabels.alreadyBookedFM} />
                        </Tooltip>

                        {alreadyBooked === true ? (
                            <Box marginTop={'20px'}display={'flex'} justifyContent={'center'}>
                                <FormControl size='small'>
                                    <InputLabel id="combo-box-bd">{bookMeetingLabels.withBD}</InputLabel>
                                    <Select
                                        sx={{ width: '300px' }}
                                        id="combo-box-bd"
                                        label={bookMeetingLabels.withBD}
                                        value={selectedBD}
                                        onChange={handleChangeSelectedBD}
                                        disabled={alreadyBooked !== true}
                                    >
                                        <MenuItem sx={{ height: '18px' }} value={''}></MenuItem>
                                        {bdOwners.map((user) => {
                                            return (
                                                <MenuItem
                                                    key={user.id}
                                                    value={user.id}>{user.value}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        ) : ("")}
                    </Box>

                    <Box mt="1rem">
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                            {isLoading ? (
                                <Box sx={{ maxWidth: '310px', minWidth: '260px' }}>
                                    <LinearProgress color='info' />
                                </Box>
                            ) : (
                                <Box sx={{ marginBottom: "4px" }}>

                                </Box>
                            )}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                            <Button
                                disabled={((displayError || timeSlot == null || timeSlot === '') && alreadyBooked === false) || (alreadyBooked === true && (selectedBD == null || selectedBD === ""))}
                                onClick={handleCompleteStep}
                                variant="contained"
                                color="primary"
                            >
                                Complete
                            </Button>
                        </div>
                        <div className="backConfirm">
                            <FormHelperText error={displayError}>{displayError ? ("Please select a timeslot!") : ("")}</FormHelperText>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}>
                            <img src={textBubble} alt="Tip! You can save and resume your sign up at a later time." height={'190px'} />
                        </div>
                    </Box>
                </Box>
            </Grid2>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </Box>
    );

}

export default FirstMeetDatePicker;