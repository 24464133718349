import { Box, FormControl, FormLabel, IconButton, InputLabel, MenuItem, Select, Skeleton, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useEffect, useMemo, useState } from "react";
import { FilterMenu } from "./FIlterMenu";

import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { createQuarters } from "./helpFunctions";

const TRACK_KEY = "SCALE";

/**
 * 
 * @param {Object} param0 
 * @param {Array} param0.reportData
 * @returns 
 */
export const ScaleParticipantsTable = ({ reportData }) => {
    const [showAccumelated, setShowAccumelated] = useState(true);
    const [region, setRegion] = useState('4');
    const [activeOnly, setActiveOnly] = useState(true);
    const [foodConn, setFoodConn] = useState(false);
    const [femRep, setFemRep] = useState(false);
    const [hkrConn, setHKRConn] = useState(false);
    const [regComp, setRegComp] = useState(false);
    const [usesFilter, setUsesFilters] = useState(false);

    const quarters = createQuarters();

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (activeOnly === true || foodConn === true || femRep === true || hkrConn === true || regComp === true || region !== '4') {
            setUsesFilters(true);
        }
        else {
            setUsesFilters(false);
        }
    }, [activeOnly, foodConn, femRep, hkrConn, regComp, region]);

    const handleChangeRegion = (event) => {
        setRegion(event.target.value);
    }

    const handleSetActive = () => {
        setActiveOnly(!activeOnly);
    }

    const handleSetFoodConn = () => {
        setFoodConn(!foodConn);
    }

    const handleSetFemRep = () => {
        setFemRep(!femRep);
    }


    const handleSetHRKConn = () => {
        setHKRConn(!hkrConn);
    }


    const handleSetRegComp = () => {
        setRegComp(!regComp);
    }


    const handleChangeView = () => {
        setShowAccumelated(!showAccumelated);
    }

    const getCurrentQuarter = () => {
        let curDate = new Date();
        //console.log("Date", curDate.getFullYear(), curDate.getMonth() + 1)
        let quarter = String(curDate.getFullYear());

        switch (curDate.getMonth() + 1) {
            case 1: case 2: case 3:
                quarter += '-Q1';
                break;
            case 4: case 5: case 6:
                quarter += '-Q2';
                break;
            case 7: case 8: case 9:
                quarter += '-Q3';
                break;
            case 10: case 11: case 12:
                quarter += '-Q4';
                break;
            default:
                break;
        }

        return quarter;
    }

    const filterProjectsAcc = (item) => {
        if (((activeOnly === true && item?.isActive === true) || activeOnly === false) &&
            ((foodConn === true && item?.hasFoodConn === true) || foodConn === false) &&
            ((femRep === true && item?.hasFemaleRep === true) || femRep === false) &&
            ((hkrConn === true && item?.hasHKRConn === true) || hkrConn === false) &&
            ((regComp === true && item?.hasRegComp === true) || regComp === false) &&
            item?.closestRegion <= Number(region)) {
            return true;
        }
        else {
            return false;
        }
    }

    const tableData = useMemo(() => {
        if (reportData.length > 0) {
            let localList = {};

            if (showAccumelated === true) {
                const quarterKeys = Object.keys(quarters);

                for (let index = 0; index < quarterKeys.length; index++) {
                    const quarter = quarterKeys[index];
                    if (localList[quarter] == null) {
                        localList[quarter] = {};
                    }

                    const projectCreated = reportData.filter((item) => item?.activeTrack === TRACK_KEY && item?.createdQuarter <= quarter && (item?.trackActivation?.[TRACK_KEY] == null || item?.trackActivation?.[TRACK_KEY] > quarter) && (item?.trackConfirmation?.[TRACK_KEY] == null || item?.trackConfirmation?.[TRACK_KEY] > quarter));
                    localList[quarter]['created'] = projectCreated.length;

                    const projectStepPending = reportData.filter((item) => item?.activeTrack === TRACK_KEY && item?.trackActivation?.[TRACK_KEY] != null && item?.trackActivation?.[TRACK_KEY] <= quarter && (item?.trackConfirmation?.[TRACK_KEY] == null || item?.trackConfirmation?.[TRACK_KEY] > quarter));
                    localList[quarter]['pending'] = projectStepPending.length;

                    const projectStepApproved = reportData.filter((item) => item?.activeTrack === TRACK_KEY && item?.trackConfirmation?.[TRACK_KEY] != null && item?.trackConfirmation?.[TRACK_KEY] <= quarter);
                    localList[quarter]['approved'] = projectStepApproved.length;
                }
            }
            else {
                for (let index = 0; index < reportData.length; index++) {
                    const proj = reportData[index];
                    if (proj.activeTrack === TRACK_KEY) {

                        if (proj?.createdQuarter != null) {
                            if (localList[proj?.createdQuarter] == null) {
                                localList[proj?.createdQuarter] = {};
                            }

                            if (localList[proj?.createdQuarter]['created'] == null) {
                                localList[proj?.createdQuarter]['created'] = 1;
                            }
                            else {
                                localList[proj?.createdQuarter]['created'] += 1;
                            }
                        }

                        if (proj?.trackActivation != null && proj?.trackActivation[TRACK_KEY] != null && (proj?.trackConfirmation[TRACK_KEY] == null || proj?.trackConfirmation[TRACK_KEY] > proj?.trackActivation[TRACK_KEY])) {
                            if (localList[proj?.trackActivation[TRACK_KEY]] == null) {
                                localList[proj?.trackActivation[TRACK_KEY]] = {};
                            }

                            if (localList[proj?.trackActivation[TRACK_KEY]]['pending'] == null) {
                                localList[proj?.trackActivation[TRACK_KEY]]['pending'] = 1;
                            }
                            else {
                                localList[proj?.trackActivation[TRACK_KEY]]['pending'] += 1;
                            }
                        }


                        if (proj?.trackConfirmation != null && proj?.trackConfirmation[TRACK_KEY] != null) {
                            if (localList[proj?.trackConfirmation[TRACK_KEY]] == null) {
                                localList[proj?.trackConfirmation[TRACK_KEY]] = {};
                            }

                            if (localList[proj?.trackConfirmation[TRACK_KEY]]['approved'] == null) {
                                localList[proj?.trackConfirmation[TRACK_KEY]]['approved'] = 1;
                            }
                            else {
                                localList[proj?.trackConfirmation[TRACK_KEY]]['approved'] += 1;
                            }
                        }
                    }
                }
            }


            return localList;
        }
        else {
            return {};
        }
    }, [quarters, reportData, showAccumelated])

    const tableDataFiltered = useMemo(() => {
        if (reportData.length > 0) {
            let localList = {};

            if (showAccumelated === true) {
                const quarterKeys = Object.keys(quarters);

                for (let index = 0; index < quarterKeys.length; index++) {
                    const quarter = quarterKeys[index];

                    if (localList[quarter] == null) {
                        localList[quarter] = {};
                    }

                    const projectCreated = reportData.filter((item) => item?.activeTrack === TRACK_KEY && filterProjectsAcc(item) === true && item?.createdQuarter <= quarter && (item?.trackActivation?.[TRACK_KEY] == null || item?.trackActivation?.[TRACK_KEY] > quarter) && (item?.trackConfirmation?.[TRACK_KEY] == null || item?.trackConfirmation?.[TRACK_KEY] > quarter));
                    localList[quarter]['created'] = projectCreated.length;

                    const projectStepPending = reportData.filter((item) => item?.activeTrack === TRACK_KEY && filterProjectsAcc(item) === true && item?.trackActivation?.[TRACK_KEY] != null && item?.trackActivation?.[TRACK_KEY] <= quarter && (item?.trackConfirmation?.[TRACK_KEY] == null || item?.trackConfirmation?.[TRACK_KEY] > quarter));
                    localList[quarter]['pending'] = projectStepPending.length;

                    const projectStepApproved = reportData.filter((item) => item?.activeTrack === TRACK_KEY && filterProjectsAcc(item) === true && item?.trackConfirmation?.[TRACK_KEY] != null && item?.trackActivation?.[TRACK_KEY] <= quarter);
                    localList[quarter]['approved'] = projectStepApproved.length;
                }
            } else {
                for (let index = 0; index < reportData.length; index++) {
                    const proj = reportData[index];
                    if (proj.activeTrack === TRACK_KEY) {
                        if (((activeOnly === true && proj?.isActive === true) || activeOnly === false) &&
                            ((foodConn === true && proj?.hasFoodConn === true) || foodConn === false) &&
                            ((femRep === true && proj?.hasFemaleRep === true) || femRep === false) &&
                            ((hkrConn === true && proj?.hasHKRConn === true) || hkrConn === false) &&
                            ((regComp === true && proj?.hasRegComp === true) || regComp === false) &&
                            proj?.closestRegion <= Number(region)) {

                            if (proj?.createdQuarter != null) {
                                if (localList[proj?.createdQuarter] == null) {
                                    localList[proj?.createdQuarter] = {};
                                }

                                if (localList[proj?.createdQuarter]['created'] == null) {
                                    localList[proj?.createdQuarter]['created'] = 1;
                                }
                                else {
                                    localList[proj?.createdQuarter]['created'] += 1;
                                }
                            }

                            if (proj?.trackActivation != null && proj?.trackActivation[TRACK_KEY] != null && (proj?.trackConfirmation[TRACK_KEY] == null || proj?.trackConfirmation[TRACK_KEY] > proj?.trackActivation[TRACK_KEY])) {
                                if (localList[proj?.trackActivation[TRACK_KEY]] == null) {
                                    localList[proj?.trackActivation[TRACK_KEY]] = {};
                                }

                                if (localList[proj?.trackActivation[TRACK_KEY]]['pending'] == null) {
                                    localList[proj?.trackActivation[TRACK_KEY]]['pending'] = 1;
                                }
                                else {
                                    localList[proj?.trackActivation[TRACK_KEY]]['pending'] += 1;
                                }
                            }


                            if (proj?.trackConfirmation != null && proj?.trackConfirmation[TRACK_KEY] != null) {
                                if (localList[proj?.trackConfirmation[TRACK_KEY]] == null) {
                                    localList[proj?.trackConfirmation[TRACK_KEY]] = {};
                                }

                                if (localList[proj?.trackConfirmation[TRACK_KEY]]['approved'] == null) {
                                    localList[proj?.trackConfirmation[TRACK_KEY]]['approved'] = 1;
                                }
                                else {
                                    localList[proj?.trackConfirmation[TRACK_KEY]]['approved'] += 1;
                                }
                            }
                        }
                    }
                }
            }

            return localList;
        }
        else {
            return {};
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData, showAccumelated, quarters, activeOnly, foodConn, femRep, hkrConn, regComp, region])

    const loading = useMemo(() => {
        if (Object.keys(tableData).length > 0) {
            return false;
        }
        else {
            return true;
        }
    }, [tableData]);

    const countTotal = (row) => {
        let total = 0;
        if (row != null) {
            Object.keys(row).forEach((key) => {
                total += Number(row[key]);
            });
        }

        return total;
    }

    const displayFilteredData = (data) => {
        if (usesFilter) {
            if (data != null) {
                return "(" + data + ")"
            }
            else {
                return "(0)"
            }
        }
        else {
            return "";
        }
    }

    const displayFilter = () => {
        let text = "";

        let usedFilter = [];

        if (foodConn === true) {
            usedFilter.push("Food connection");
        }
        if (femRep === true) {
            usedFilter.push("Female representation");
        }
        if (hkrConn === true) {
            usedFilter.push("Connection to HKR");
        }
        if (regComp === true) {
            usedFilter.push("Registered company");
        }
        if (activeOnly === true) {
            usedFilter.push("Active only");
        }

        if (usedFilter.length > 0) {
            text = "(" + usedFilter.join(', ') + ")"
        }

        return text;
    }

    return (
        <>
            <Box>
                <Stack direction={'row'} justifyContent={"space-between"} alignItems={"flex-end"} style={{ marginTop: '5px' }}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                        <FormLabel>New per quarter</FormLabel>
                        <Switch checked={showAccumelated} onClick={handleChangeView} />
                        <FormLabel>Accumulated</FormLabel>
                    </Stack>

                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        {usesFilter ? (
                            <div style={{ color: '#666666' }}>
                                <i>{displayFilter()}</i>
                            </div>
                        ) : ("")}
                        <FormControl size="small">
                            <InputLabel>Region</InputLabel>
                            <Select
                                style={{ width: "170px" }}
                                value={region}
                                label="Region"
                                onChange={handleChangeRegion}
                            >
                                <MenuItem value={'4'}>All</MenuItem>
                                <MenuItem value={'3'}>Sweden</MenuItem>
                                <MenuItem value={'2'}>Skåne</MenuItem>
                                <MenuItem value={'1'}>Skåne Nordost</MenuItem>
                            </Select>
                        </FormControl>

                        <IconButton onClick={handleOpenMenu}>
                            <TuneRoundedIcon />
                        </IconButton>
                    </Stack>
                </Stack>

                <Table
                    sx={{ justifyContent: 'center', width: '1200px' }}
                    aria-labelledby="statsTable"
                    aria-label="Stats Table">
                    <TableHead>
                        <TableRow>
                            <TableCell key={"quarterHeader"}>Quarter</TableCell>
                            <TableCell key={"createdHeader"}>Signed up</TableCell>
                            <TableCell key={"pendingHeader"}>Pending</TableCell>
                            <TableCell key={"approvedHeader"}>Approved</TableCell>
                            <TableCell key={"totalHeader"}>Total</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {Object.keys(quarters).sort((a, b) => String(b).localeCompare(a)).map((key, index) => {
                            if (loading) {
                                return (
                                    <TableRow key={key + "row"}>
                                        <TableCell key={"quarterField-" + key + "-" + index}>
                                            <i>{key}*</i>
                                        </TableCell>
                                        <TableCell key={"createdData-" + key + "-" + index} width={'140px'}>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell key={"pendingData-" + key + "-" + index} width={'140px'}>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell key={"approvedData-" + key + "-" + index}  width={'140px'}>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell key={"totalData-" + key + "-" + index} width={'140px'}>
                                            <Skeleton />
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                            else {
                                if (key === getCurrentQuarter()) {
                                    return (
                                        <TableRow style={{ backgroundColor: '#17a2b830' }} key={key + "row"}>
                                            <TableCell key={"quarterField-" + key + "-" + index}>
                                                <i>{key}*</i>
                                            </TableCell>
                                            <TableCell key={"createdData-" + key + "-" + index} width={'140px'}>
                                                <i>{tableData?.[key]?.['created'] || '0'} {displayFilteredData(tableDataFiltered?.[key]?.['created'])}</i>
                                            </TableCell>
                                            <TableCell key={"pendingData-" + key + "-" + index} width={'140px'}>
                                                <i>{tableData?.[key]?.['pending'] || '0'} {displayFilteredData(tableDataFiltered?.[key]?.['pending'])}</i>
                                            </TableCell>
                                            <TableCell key={"approvedData-" + key + "-" + index} width={'140px'}>
                                                <i>{tableData?.[key]?.['approved'] || '0'} {displayFilteredData(tableDataFiltered?.[key]?.['approved'])}</i>
                                            </TableCell>
                                            <TableCell key={"totalData-" + key + "-" + index} width={'140px'}>
                                                <i>{countTotal(tableData[key])} {displayFilteredData(countTotal(tableDataFiltered[key]))}</i>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                                else {
                                    return (
                                        <TableRow key={key + "row"}>
                                            <TableCell key={"quarterField-" + key + "-" + index}>
                                                {key}
                                            </TableCell>
                                            <TableCell key={"createdData-" + key + "-" + index} width={'140px'}>
                                                {tableData?.[key]?.['created'] || '0'} {displayFilteredData(tableDataFiltered?.[key]?.['created'])}
                                            </TableCell>
                                            <TableCell key={"pendingData-" + key + "-" + index} width={'140px'}>
                                                {tableData?.[key]?.['pending'] || '0'} {displayFilteredData(tableDataFiltered?.[key]?.['pending'])}
                                            </TableCell>
                                            <TableCell key={"approvedData-" + key + "-" + index} width={'140px'}>
                                                {tableData?.[key]?.['approved'] || '0'} {displayFilteredData(tableDataFiltered?.[key]?.['approved'])}
                                            </TableCell>
                                            <TableCell key={"totalData-" + key + "-" + index} width={'140px'}>
                                                {countTotal(tableData[key])} {displayFilteredData(countTotal(tableDataFiltered?.[key]))}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            }
                        })}
                    </TableBody>
                </Table>

                <Stack direction={'row'} justifyContent={"space-between"} alignItems={"flex-start"} style={{ marginTop: '5px', height: '20px' }}>
                    <div style={{ marginLeft: '10px' }}>
                        <i>* Current quarter</i>
                    </div>
                    <div style={{ marginRight: '40px', fontSize: '14px' }}>
                        <i>Total (Selection)</i>
                    </div>
                </Stack>
            </Box>

            <FilterMenu
                anchorEl={anchorEl}
                open={openMenu}
                handleClose={handleCloseMenu}
                foodConn={foodConn}
                handleSetFoodConn={handleSetFoodConn}
                femRep={femRep}
                handleSetFemRep={handleSetFemRep}
                hkrConn={hkrConn}
                handleSetHKRConn={handleSetHRKConn}
                regComp={regComp}
                handleSetRegComp={handleSetRegComp}
                activeOnly={activeOnly}
                handleSetActive={handleSetActive}
            />
        </>
    )
}