import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react"
import { db } from "../../firebase";
import { useGetProjects } from "../projectData/useGetProjects";
import { getUserMetaData } from "../../firebase/cloudFunctions";
import { capitalizeWord } from "../../helper/stringHelpers";


export const useGetAllUserData = () => {
    const projects = useGetProjects();
    const [userDataList, setUserDataList] = useState([]);

    useEffect(() => {
        const getAllIboUsers = async () => {
            const querySnapshot = await getDocs(query(collection(db, 'Users'), where('role', '==', 1)));
            const querySnapshotUserProj = await getDocs(query(collection(db, 'UserProject')));
            const metaData = await getUserMetaData();

            let userProjects = {};

            for (let index = 0; index < querySnapshotUserProj.docs.length; index++) {
                const element = querySnapshotUserProj.docs[index];
                userProjects[element.id] = element.data()?.projects;
            }
            let currentDate = new Date();
            let arr = [];
            querySnapshot.forEach((doc) => {
                if (doc.data().role === 1 && doc.data().dataSet >= 3) {
                    if (doc.data().disabled !== true) {
                        let iboID;
                        let iboName = "-";
                        let iboStatus = "-";
                        let lastSignInTime = "-";
                        let created;

                        if (userProjects[doc.id] != null && userProjects[doc.id].length > 0) {
                            iboID = userProjects[doc.id][0];
                        }

                        if (iboID != null) {
                            let projData = projects.find((item) => item.id === iboID);

                            if (projData != null) {
                                iboName = projData?.value;
                                iboStatus = getStatus(projData?.isActive);
                            }
                        }

                        if (metaData?.data != null && metaData?.data[doc.id] != null) {
                            if (metaData?.data[doc.id].lastSignIn != null) {
                                let signInDate = new Date(metaData?.data[doc.id].lastSignIn);
                                lastSignInTime = signInDate.toLocaleString('se-SV');
                            }

                            if (metaData?.data[doc.id].created != null) {
                                let createdDate = new Date(metaData?.data[doc.id].created);
                                created = createdDate.toLocaleString('se-SV');
                            }
                        }

                        let fullUserName = doc.data().firstName + " " + doc.data().lastName
                        if (iboStatus !== 'blocked' && doc.data()?.disabled !== true) {
                            arr.push({
                                id: doc.id,
                                userCreated: created,
                                lastSignin: lastSignInTime,
                                name: fullUserName.trim(),
                                city: displayCity(doc.data()),
                                email: doc.data().email,
                                gender: doc.data().gender,
                                prefLang: capitalizeWord(doc.data().prefLang),
                                dob: doc.data().dateOfBirth,
                                age: getAge(doc.data().dateOfBirth),
                                hkrConn: doc.data().hkrConnection,
                                ibo: iboName,
                                iboStatus: iboStatus,
                            });
                        }
                    }
                }
            });

            setUserDataList(arr);
        }

        if (projects.length > 0) {
            getAllIboUsers();
        }

    }, [projects]);

    return userDataList;
}

const displayCity = (userData) => {
    let cityString = " - ";
    if (userData?.city != null && userData?.city !== "") {
        cityString = userData.city;
    }

    if (userData?.county != null && userData?.county !== "") {
        cityString += " / " + userData.county;
    }

    if (userData?.region != null && userData?.region !== "" && userData?.region !== 'Outside Sweden') {
        cityString += " / " + userData.region;
    }

    if (userData?.country != null && userData?.country !== "") {
        cityString += " / " + userData.country;
    }
    return cityString;
}

const getStatus = (isActive) => {
    let status = "";
    switch (isActive) {
        case 'blocked':
            status = 'blocked';
            break;
        case 'paused':
            status = "Paused";
            break;
        case 'aborted':
            status = "Inactive (Aborted on IBOs request)";
            break;
        case 'exit':
            status = "Inactive (Has exited)";
            break;
        case false:
            status = "Inactive";
            break;
        case true:
            status = "Active";
            break;
        default:
            status = "Active";
            break;
    }

    return status;
}

const getAge = (dateString) => {
    if (dateString != null) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return String(age);
    }
    else {
        return "-"
    }
}