import { useMemo, useState } from "react";
import { Box, CircularProgress, Collapse, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip } from 'recharts';
import { genderFields, regionGroupLabels, regionGroups } from "../helpFunctions";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { capitalizeWord } from "../../../../../helper/stringHelpers";

export const RegionIBOGraph = ({ isLoading, regionData }) => {
    const chartData = useMemo(() => {
        let data = []

        Object.keys(regionGroupLabels).forEach((key) => {
            data.push({ name: regionGroupLabels[key], count: regionData[key] });
        });

        return data;
    }, [regionData]);


    return (
        <>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            ) : (
                <Stack spacing={5}>
                    <BarChart
                        width={700}
                        height={400}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="count" fill="#007bff" />
                    </BarChart>

                    <Table
                        sx={{ justifyContent: 'center' }}
                        aria-labelledby="statsTable"
                        aria-label="Stats Table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Region
                                </TableCell>
                                <TableCell key={"userHeader"}>Users</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(regionGroupLabels).map((option, index) => {
                                return (
                                    <TableRow key={option}>
                                        <TableCell>
                                            {regionGroupLabels[option]}
                                        </TableCell>
                                        <TableCell key={option + "-" + index}>
                                            {regionData[option]}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Stack>
            )}
        </>
    )
}