import { useMemo, useState } from "react";
import { Box, CircularProgress, Collapse, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip } from 'recharts';
import { genderFields, regionGroupLabels, regionGroups } from "../helpFunctions";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { capitalizeWord } from "../../../../../helper/stringHelpers";

export const RegionGraph = ({ isLoading, regionData, regionDataGender, splitByGender }) => {
    const [chartData, setChartData] = useState([]);

    const tableData = useMemo(() => {
        let data = {
            northEastScania: {
                areas: {},
            },
            scania: {
                areas: {},
            },
            sweden: {
                areas: {},
            },
            other: {
                areas: {},
            },
        }

        let totalNorthEast = 0;
        let totalNorthEastMale = 0;
        let totalNorthEastFemale = 0;
        let totalNorthEastOther = 0;

        let totalScania = 0;
        let totalScaniaMale = 0;
        let totalScaniaFemale = 0;
        let totalScaniaOther = 0;

        let totalSweden = 0;
        let totalSwedenMale = 0;
        let totalSwedenFemale = 0;
        let totalSwedenOther = 0;

        let totalOthers = 0;
        let totalOthersMale = 0;
        let totalOthersFemale = 0;
        let totalOthersOther = 0;

        if (regionData != null) {
            if (regionData['Sweden'] != null) {
                const swedenRegions = regionData['Sweden'].regions;
                if (regionData['Sweden']?.regions['Skåne'] != null) {
                    const scaniaRegions = regionData['Sweden'].regions['Skåne']?.county;

                    for (let index = 0; index < regionGroups['northEastScania'].length; index++) {
                        const element = regionGroups['northEastScania'][index];

                        if (scaniaRegions[element] != null) {
                            data.northEastScania['areas'][element] = scaniaRegions[element];

                            if (scaniaRegions[element].count != null) {
                                totalNorthEast += Number(scaniaRegions[element].count);
                            }

                            if (scaniaRegions[element]['Male']?.count != null) {
                                totalNorthEastMale += Number(scaniaRegions[element]['Male']?.count);
                            }

                            if (scaniaRegions[element]['Female']?.count != null) {
                                totalNorthEastFemale += Number(scaniaRegions[element]['Female']?.count);
                            }

                            if (scaniaRegions[element]['Other']?.count != null) {
                                totalNorthEastOther += Number(scaniaRegions[element]['Other']?.count);
                            }
                        }
                    }

                    for (let index = 0; index < regionGroups['scania'].length; index++) {
                        const element = regionGroups['scania'][index];

                        if (scaniaRegions[element] != null) {
                            if (data.scania['areas'] == null) {
                                data.scania['areas'] = {}
                            }

                            data.scania['areas'][element] = scaniaRegions[element];

                            if (scaniaRegions[element].count != null) {
                                totalScania += Number(scaniaRegions[element].count);
                            }

                            if (scaniaRegions[element]['Male']?.count != null) {
                                totalScaniaMale += Number(scaniaRegions[element]['Male']?.count);
                            }

                            if (scaniaRegions[element]['Female']?.count != null) {
                                totalScaniaFemale += Number(scaniaRegions[element]['Female']?.count);
                            }

                            if (scaniaRegions[element]['Other']?.count != null) {
                                totalScaniaOther += Number(scaniaRegions[element]['Other']?.count);
                            }
                        }
                    }

                    data.northEastScania.count = totalNorthEast;
                    data.northEastScania.countMale = totalNorthEastMale;
                    data.northEastScania.countFemale = totalNorthEastFemale;
                    data.northEastScania.countOther = totalNorthEastOther;

                    data.scania.count = totalScania;
                    data.scania.countMale = totalScaniaMale;
                    data.scania.countFemale = totalScaniaFemale;
                    data.scania.countOther = totalScaniaOther;

                }

                for (let index = 0; index < regionGroups['sweden'].length; index++) {
                    const element = regionGroups['sweden'][index];

                    if (swedenRegions[element] != null) {
                        data.sweden['areas'][element] = swedenRegions[element];

                        if (swedenRegions[element].count != null) {
                            totalSweden += Number(swedenRegions[element].count);
                        }

                        if (swedenRegions[element]['Male']?.count != null) {
                            totalSwedenMale += Number(swedenRegions[element]['Male']?.count);
                        }

                        if (swedenRegions[element]['Female']?.count != null) {
                            totalSwedenFemale += Number(swedenRegions[element]['Female']?.count);
                        }

                        if (swedenRegions[element]['Other']?.count != null) {
                            totalSwedenOther += Number(swedenRegions[element]['Other']?.count);
                        }
                    }
                }

                data.sweden.count = totalSweden;
                data.sweden.countMale = totalSwedenMale;
                data.sweden.countFemale = totalSwedenFemale;
                data.sweden.countOther = totalSwedenOther;
            }

            for (let index = 0; index < regionGroups['other'].length; index++) {
                const element = regionGroups['other'][index];

                if (regionData[element] != null) {
                    data.other['areas'][element] = regionData[element];

                    if (regionData[element].count != null) {
                        totalOthers += Number(regionData[element].count);
                    }

                    if (regionData[element]['Male']?.count != null) {
                        totalOthersMale += Number(regionData[element]['Male']?.count);
                    }

                    if (regionData[element]['Female']?.count != null) {
                        totalOthersFemale += Number(regionData[element]['Female']?.count);
                    }

                    if (regionData[element]['Other']?.count != null) {
                        totalOthersOther += Number(regionData[element]['Other']?.count);
                    }
                }
            }

            data.other.count = totalOthers;
            data.other.countMale = totalOthersMale;
            data.other.countFemale = totalOthersFemale;
            data.other.countOther = totalOthersOther;
        }

        let chartDataLocal = [];
        if (splitByGender === true) {
            Object.keys(regionGroupLabels).forEach((key) => {
                let countMale = 0;
                let countFemale = 0;
                let countOther = 0;

                if (data[key]?.countMale != null){
                    countMale = data[key]?.countMale;
                }
                if (data[key]?.countFemale != null){
                    countFemale = data[key]?.countFemale;
                }
                if (data[key]?.countOther != null){
                    countOther = data[key]?.countOther;
                }
                chartDataLocal.push({ name: regionGroupLabels[key], male: countMale, female: countFemale, other: countOther });
            })
        }
        else {
            Object.keys(regionGroupLabels).forEach((key) => {
                let count = 0;
                if (data[key]?.count != null){
                    count = data[key]?.count;
                }
                chartDataLocal.push({ name: regionGroupLabels[key], count: count });
            })
        }
        setChartData(chartDataLocal);
        console.log("Data: ", data)
        return data;
    }, [regionData, regionDataGender, splitByGender]);


    return (
        <>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            ) : (
                <Stack spacing={5}>
                    <BarChart
                        width={700}
                        height={400}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {splitByGender === true ? (
                            <>
                                <Bar dataKey="male" fill="#28a745" />
                                <Bar dataKey="female" fill="#fd7e14" />
                                <Bar dataKey="other" fill="#6610f2" />
                            </>
                        ) : (
                            <>
                                <Bar dataKey="count" fill="#007bff" />
                            </>
                        )}
                    </BarChart>

                    <Table
                        sx={{ justifyContent: 'center' }}
                        aria-labelledby="statsTable"
                        aria-label="Stats Table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                    Region
                                </TableCell>
                                {splitByGender === true ? (
                                    <>
                                        {Object.keys(genderFields).map((gender) => {
                                            return (
                                                <TableCell key={gender}>
                                                    {genderFields[gender]}
                                                </TableCell>);
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <TableCell key={"userHeader"}>Users</TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(tableData).map((itemKey) => {
                                return (
                                    <AreaRow key={itemKey} rowKey={itemKey} rowData={tableData[itemKey]} splitByGender={splitByGender} />
                                )
                            })}
                        </TableBody>
                    </Table>
                </Stack>
            )}
        </>
    )
}

const AreaRow = ({ rowKey, rowData, splitByGender }) => {
    const [open, setOpen] = useState(false);

    const subAreaLabel = () => {
        if (rowKey === 'northEastScania' || rowKey === 'scania') {
            return "Municipality"
        }
        else if (rowKey === 'sweden') {
            return "Region"
        }
        else {
            return "Country"
        }
    }

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, }}>
                <TableCell>
                    {rowData?.areas != null ? (
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    ) : ("")}
                </TableCell>

                <TableCell>
                    {regionGroupLabels[rowKey]}
                </TableCell>

                {splitByGender === true ? (
                    <>
                        <TableCell>
                            {rowData?.countMale}
                        </TableCell>
                        <TableCell>
                            {rowData?.countFemale}
                        </TableCell>
                        <TableCell>
                            {rowData?.countOther}
                        </TableCell>
                    </>
                ) : (
                    <>
                        <TableCell>
                            {rowData?.count}
                        </TableCell>
                    </>
                )}
            </TableRow>
            {rowData?.areas != null ? (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 2, backgroundColor: '#51b2c910', borderRadius: '8px' }}>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>
                                                {subAreaLabel()}
                                            </TableCell>
                                            {splitByGender === true ? (
                                                <>
                                                    {Object.keys(genderFields).map((gender) => {
                                                        return (
                                                            <TableCell key={gender}>
                                                                {genderFields[gender]}
                                                            </TableCell>);
                                                    })}
                                                </>
                                            ) : (
                                                <TableCell>Users</TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody sx={{}}>
                                        {Object.keys(rowData.areas).map((itemKey) => {
                                            return (
                                                <SubAreaRow key={itemKey} rowKey={itemKey} rowData={rowData.areas[itemKey]} splitByGender={splitByGender} />
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            ) : ("")}

        </>
    )
}

const SubAreaRow = ({ rowKey, rowData, splitByGender }) => {
    const [open, setOpen] = useState(false);
    
    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell>
                    {rowKey}
                </TableCell>

                {splitByGender === true ? (
                    <>
                        <TableCell>
                            {rowData?.['Male']?.count != null ? rowData?.['Male']?.count : 0}
                        </TableCell>
                        <TableCell>
                            {rowData?.['Female']?.count != null ? rowData?.['Female']?.count : 0}
                        </TableCell>
                        <TableCell>
                            {rowData?.['Other']?.count != null ? rowData?.['Other']?.count : 0}
                        </TableCell>
                    </>
                ) : (
                    <>
                        <TableCell>
                            {rowData?.count}
                        </TableCell>
                    </>
                )}
            </TableRow>
            {rowData?.cities != null ? (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1, backgroundColor: '#b1d8e221', borderRadius: '8px' }}>
                                <Table padding="none" size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                City
                                            </TableCell>
                                            {splitByGender === true ? (
                                                <>
                                                    {Object.keys(genderFields).map((gender) => {
                                                        return (
                                                            <TableCell key={gender}>
                                                                {genderFields[gender]}
                                                            </TableCell>);
                                                    })}
                                                </>
                                            ) : (
                                                <TableCell>Users</TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {Object.keys(rowData.cities).map((itemKey) => {
                                            return (
                                                <TableRow key={itemKey} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                    <TableCell>
                                                        {capitalizeWord(itemKey)}
                                                    </TableCell>
                                                    {splitByGender === true ? (
                                                        <>
                                                            <TableCell>
                                                                {rowData?.cities[itemKey]?.['Male']?.count != null ? rowData?.cities[itemKey]?.['Male']?.count : 0}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowData?.cities[itemKey]?.['Female']?.count != null ? rowData?.cities[itemKey]?.['Female']?.count : 0}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowData?.cities[itemKey]?.['Other']?.count != null ? rowData?.cities[itemKey]?.['Other']?.count : 0}
                                                            </TableCell>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <TableCell>
                                                                {rowData.cities[itemKey]?.count}
                                                            </TableCell>
                                                        </>
                                                    )}
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            ) : ("")}
        </>
    )
}