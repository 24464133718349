import { useEffect, useMemo, useState } from "react";
import { useGetFullIBOData } from "../projectData/useGetFullIBOData";
import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { CompanyChoices } from "../../constants/labels/labels";


export const useGetReportsData = () => {
    const fullIBOList = useGetFullIBOData();

    const [fullReportData, setFullReportData] = useState([])

    useEffect(() => {
        const runStepQuery = async () => {
            const stepQuery = query(collectionGroup(db, "Steps"), where('type', '==', 'Meeting'), where('meetState', '==', 'Completed'));

            const stepData = await getDocs(stepQuery);

            let localDataList = [];
            for (let index = 0; index < fullIBOList.length; index++) {
                const projectData = fullIBOList[index];

                if (projectData?.isDev !== true) {
                    //console.log("Proj: ", projectData)
                    let isActive = false;
                    let activeTrack = "START";
                    let createdQuarter;

                    let hasFemaleRep = false;
                    let hasHKRConn = false;
                    let hasFoodConn = false;
                    let hasRegComp = false;
                    let trackParticipation = {};
                    let trackActivation = {};
                    let trackConfirmation = {};
                    let trackExited = {};
                    let closestRegion;

                    if (projectData?.isActive === true) {
                        isActive = true;
                    }
                    else if (projectData?.isActive != null){
                        isActive = projectData?.isActive;
                    }

                    if (projectData?.activeTrack != null) {
                        activeTrack = String(projectData?.activeTrack).toLocaleUpperCase();
                    }

                    if (projectData?.trackParticipation != null){
                        trackParticipation = projectData?.trackParticipation;
                    }

                    if (projectData?.trackExited != null){
                        const exitedDates = projectData?.trackActivation;
                        let exitedQuarters = {};
                        Object.keys(exitedDates).forEach((key) => {
                            if (exitedDates[key] != null){
                                exitedQuarters[key.toLocaleUpperCase()] = getRelevantQuarter(exitedDates[key].toDate());
                            }
                        });

                        trackExited = exitedQuarters;
                    }

                    if (projectData?.trackActivation != null){
                        const activationsDates = projectData?.trackActivation;
                        let activationQuarters = {};
                        Object.keys(activationsDates).forEach((key) => {
                            if (activationsDates[key] != null){
                                activationQuarters[key.toLocaleUpperCase()] = getRelevantQuarter(activationsDates[key].toDate());
                            }
                        });

                        trackActivation = activationQuarters;
                    }

                    if (projectData?.trackConfirmation != null){
                        const confirmationDates = projectData?.trackConfirmation;
                        let confirmationQuarters = {};
                        Object.keys(confirmationDates).forEach((key) => {
                            if (confirmationDates[key] != null){
                                confirmationQuarters[key.toLocaleUpperCase()] = getRelevantQuarter(confirmationDates[key].toDate());
                            }
                        });

                        trackConfirmation = confirmationQuarters;
                    }

                    if (projectData?.created != null && projectData?.created !== "") {
                        createdQuarter = getRelevantQuarter(projectData?.created.toDate());
                    }

                    if (projectData?.entryQuestions?.foodConnection?.answer != null) {
                        if (projectData?.entryQuestions?.foodConnection?.answer === 'yes') {
                            hasFoodConn = true;
                        }
                    }

                    if (projectData?.existingComp === 'yes_new' || projectData?.existingComp === CompanyChoices['yes_new'] || projectData?.existingComp === 'yes_established' || projectData?.existingComp === CompanyChoices['yes_established']) {
                        hasRegComp = true;
                    }

                    if (projectData?.IBOUserData != null && projectData?.IBOUserData.length > 0) {
                        for (let index = 0; index < projectData?.IBOUserData.length; index++) {
                            const userData = projectData?.IBOUserData[index];

                            if (userData?.gender === 'Female') {
                                hasFemaleRep = true;
                            }

                            if (userData?.hkrConnection != null && userData?.hkrConnection !== '' && userData?.hkrConnection !== 'None') {
                                hasHKRConn = true;
                            }

                            if (userData?.country === 'Sweden' || userData?.country == null) {
                                if (userData?.region === 'Skåne') {
                                    if (userData?.county === 'Kristianstad' || userData?.county === 'Hässleholm' || userData?.county === 'Östra Göinge' || userData?.county === 'Osby' || userData?.county === 'Bromölla' || userData?.county === 'Hörby') {
                                        closestRegion = 1;
                                    }
                                    else {
                                        if (closestRegion > 2 || closestRegion == null) {
                                            closestRegion = 2;
                                        }
                                    }
                                }
                                else if (userData?.region != null) {
                                    if (closestRegion > 3 || closestRegion == null) {
                                        closestRegion = 3;
                                    }
                                }
                            }
                            else if (userData?.country != null) {
                                if (closestRegion == null) {
                                    closestRegion = 4;
                                }
                            }
                        }
                    }

                    let localProjData = {
                        id: projectData.id,
                        isActive: isActive,
                        activeTrack: activeTrack,
                        closestRegion: closestRegion,
                        hasFemaleRep: hasFemaleRep,
                        hasHKRConn: hasHKRConn,
                        hasFoodConn: hasFoodConn,
                        hasRegComp: hasRegComp,
                        createdQuarter: createdQuarter,
                        trackParticipation: trackParticipation,
                        trackActivation: trackActivation,
                        trackConfirmation: trackConfirmation,
                        trackExited: trackExited,
                    };
                    const steps = stepData.docs.filter((obj) => obj.ref.parent.parent.id === projectData.id);

                    //console.log("Project: ", projectData.id, steps);
                    if (steps.length > 0) {
                        steps.sort((a, b) => a.data().order - b.data().order);

                        for (let index = 0; index < steps.length; index++) {
                            const stepData = steps[index];

                            if (stepData.data()?.nextTime != null) {
                                const quarter = getRelevantQuarter(stepData.data()?.nextTime.toDate());

                                //console.log(projectData.id, "Step: ", stepData.id, "Quarter: ", quarter);
                                localProjData[stepData.id] = quarter;
                            }
                        }
                    }

                    localDataList.push(localProjData);
                }
            }

            setFullReportData(localDataList);
        }

        if (fullIBOList.length > 0) {
            runStepQuery();
        }
    }, [fullIBOList]);

    return fullReportData;
}

/**
 * 
 * @param {Date} date 
 */
const getRelevantQuarter = (date) => {
    let createdYear = date.getFullYear();
    let createdMonth = date.getMonth() + 1;
    let createdQuarter = String(createdYear);

    switch (createdMonth) {
        case 1: case 2: case 3:
            createdQuarter += '-Q1';
            break;
        case 4: case 5: case 6:
            createdQuarter += '-Q2';
            break;
        case 7: case 8: case 9:
            createdQuarter += '-Q3';
            break;
        case 10: case 11: case 12:
            createdQuarter += '-Q4';
            break;
        default:
            break;
    }

    return createdQuarter;
}