import { useMemo } from "react";
import { CircularProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip } from 'recharts';
import { genderFields } from "../helpFunctions";

export const ReferenceGraph = ({ isLoading, refData, refDataGender, splitByGender }) => {
    const chartData = useMemo(() => {
        let data = []
        if (splitByGender === true) {
            Object.keys(refData).forEach((ref) => {
                data.push({ name: ref, male: refDataGender['Male'][ref] || 0, female: refDataGender['Female'][ref] || 0, other: refDataGender['Other'][ref] || 0 });
            })
        }
        else {
            Object.keys(refData).forEach((key) => {
                data.push({ name: key, count: refData[key] });
            })
        }
        return data;
    }, [refData, refDataGender, splitByGender]);

    return (
        <>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            ) : (
                <Stack spacing={5}>
                    <BarChart
                        width={700}
                        height={400}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {splitByGender === true ? (
                            <>
                                <Bar dataKey="male" fill="#28a745" />
                                <Bar dataKey="female" fill="#fd7e14" />
                                <Bar dataKey="other" fill="#6610f2" />
                            </>
                        ) : (
                            <>
                                <Bar dataKey="count" fill="#007bff" />
                            </>
                        )}
                    </BarChart>

                    <Table
                        sx={{ justifyContent: 'center' }}
                        aria-labelledby="statsTable"
                        aria-label="Stats Table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Reference
                                </TableCell>
                                {splitByGender === true ? (
                                    <>
                                        {Object.keys(genderFields).map((gender) => {
                                            return (
                                                <TableCell key={gender}>
                                                    {genderFields[gender]}
                                                </TableCell>);
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <TableCell key={"userHeader"}>Number of users</TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {chartData.map((row, index) => {
                                if (splitByGender === true) {
                                    return (
                                        <TableRow key={row.name}>
                                            <TableCell key={row.name + '- title'}>
                                                {row.name}
                                            </TableCell>
                                            {Object.keys(genderFields).map((gender) => {
                                                return (
                                                    <TableCell key={row.name + '-' + gender}>
                                                        {row[gender.toLocaleLowerCase()] || 0}
                                                    </TableCell>);
                                            })}
                                        </TableRow>
                                    );
                                }
                                else {
                                    return (
                                        <TableRow key={row.name + "row"}>
                                            <TableCell key={"refField-" + row.name + "-" + index}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell key={"refData-" + row.name + "-" + index}>
                                                {row.count}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            })}
                        </TableBody>
                    </Table>
                </Stack>
            )}
        </>
    )
}