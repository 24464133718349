export const createQuarters = () => {
    let quarters = {};
    const today = new Date(Date.now());
    let curYear = today.getFullYear();
    let curMonth = today.getMonth() + 1;
    let curQuarter = 1;

    switch (curMonth) {
        case 1: case 2: case 3:
            curQuarter = 1;
            break;
        case 4: case 5: case 6:
            curQuarter = 2;
            break;
        case 7: case 8: case 9:
            curQuarter = 3;
            break;
        case 10: case 11: case 12:
            curQuarter = 4;
            break;
        default:
            break;
    }

    for (let year = 2022; year <= curYear; year++) {
        let startQ = 1;
        let endQ = 4;

        if (year === curYear) {
            endQ = curQuarter;
        }

        for (let quarter = startQ; quarter <= endQ; quarter++) {
            let quarterString = String(year) + "-Q" + String(quarter)

            let finalDateQuarter = new Date();
            let finalMonth;
            switch (quarter) {
                case 1:
                    finalMonth = 3;
                    break;
                case 2:
                    finalMonth = 6;
                    break;
                case 3:
                    finalMonth = 9;
                    break;
                case 4:
                    finalMonth = 12;
                    break;
                default:
                    break;
            }

            finalDateQuarter.setFullYear(year, finalMonth, 1);
            finalDateQuarter.setDate(finalDateQuarter.getDate() - 1);

            quarters[quarterString] = finalDateQuarter;
        }
    }

    return quarters;
}