import { collectionGroup, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react"
import { db } from "../../firebase";

export const useGetAllCompanyInfo = () => {
    const [companyDataList, setCompanyDataList] = useState(Object);

    useEffect(() => {
        const getAllProjectsCompInfo = async () => {
            const companySnap = await getDocs(collectionGroup(db, 'CompanyInfo'));

            const companyData = {};
            companySnap.forEach((child) => {
                companyData[child.ref.parent.parent.id] = child.data();
            });

            setCompanyDataList(companyData);
        };

        getAllProjectsCompInfo();
    }, []);

    return companyDataList;
}