import { Checkbox, FormLabel, Menu } from "@mui/material"


export const FilterMenu = ({
    anchorEl,
    open,
    handleClose,
    foodConn,
    femRep,
    hkrConn,
    regComp,
    activeOnly = null,
    handleSetFoodConn,
    handleSetFemRep,
    handleSetHKRConn,
    handleSetRegComp,
    handleSetActive = null
}) => {

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            border: `1px solid #4eacc1`,
                            borderRadius: 1.7,
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1,
                            paddingX: '8px',
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                color: 'white',
                                top: -0.6,
                                right: 15,
                                width: 9,
                                height: 9,
                                bgcolor: 'white',
                                borderTop: `1px solid #4eacc1`,
                                borderLeft: `1px solid #4eacc1`,
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <div>
                    <Checkbox size="small" checked={foodConn} onClick={handleSetFoodConn} />
                    <FormLabel><i>Food connection</i></FormLabel>
                </div>
                <div>
                    <Checkbox size="small" checked={femRep} onClick={handleSetFemRep} />
                    <FormLabel><i>Female representation</i></FormLabel>
                </div>
                <div>
                    <Checkbox size="small" checked={hkrConn} onClick={handleSetHKRConn} />
                    <FormLabel><i>Connection to HKR</i></FormLabel>
                </div>
                <div>
                    <Checkbox size="small" checked={regComp} onClick={handleSetRegComp} />
                    <FormLabel><i>Registered company</i></FormLabel>
                </div>
                {activeOnly != null && handleSetActive != null ? (
                    <div>
                        <Checkbox size="small" checked={activeOnly} onClick={handleSetActive} />
                        <FormLabel><i>Active Only</i></FormLabel>
                    </div>
                ) : ("")}
            </Menu>
        </>
    )
}